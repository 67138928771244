import React, { useState, useEffect } from 'react'
import { PERMISSION_TYPE, DEFAULT_API_ADMIN } from '@/constants'
import hasPermission from '@lib/hasPermission'

import {
  Wrapper,
  RowFilter,
  Loader,
  Title,
  TableGeneric,
  DropDownPartners,
  InnerRow,
} from '@components'
import { DatePicker } from 'material-ui-pickers'

import moment from 'moment'
import { to, get, getPlaceId } from '@lib'

const { PARTNER_MANAGER } = PERMISSION_TYPE

const Permission = ({ roles, ...props }) =>
  hasPermission(roles) ? <div {...props} /> : null

const redeemedChoices = [
  {
    id: false, name: 'Não resgatado',
  },
  {
    id: true, name: 'Resgatado',
  },
]

const CouponsReports = ({ data }) => (
  <>
    <TableGeneric
      headCells={['Total', 'Não resgatados', 'Resgatados']}
      arrayData={data.consolidatedData}
      title="Dados"
      style={{ width: '100%', margin: '1em 0' }}
    />
    <TableGeneric
      headCells={[
        'Cupom',
        'Produto',
        'Retirado',
      ]}
      arrayData={data.tableData}
      title="Relatório"
      style={{ width: '100%' }}
    />
  </>
)

export default props => {
  const [dateFrom, setDateFrom] = useState(moment().subtract(90, 'days'))
  const [dateTo, setDateTo] = useState(moment())
  const [redeemed, setRedeemed] = useState(false)
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)

  const fetchData = async () => {
    let filter = {
      from: moment(dateFrom).format('YYYY-MM-DD'),
      to: moment(dateTo).format('YYYY-MM-DD'),
      placeId: getPlaceId(),
    }

    if (redeemed) {
      filter = {
        ...filter,
        // @ts-ignore
        redeemed,
      }
    }

    const URL = `${DEFAULT_API_ADMIN}/coupons?filter=${JSON.stringify(filter)}`
    const [error, response] = await to(get(URL))

    if (error) {
      setLoading(false)
      return
    }

    return response
  }

  const doFetch = async () => {
    setLoading(true)
    const couponsData = await fetchData()

    if (couponsData) {
      const { data: responseData, ...consolidation } = couponsData
      const formattedResponseData = responseData.map(item => ({
        id: item.id,
        product: item.product.name,
        redeemed: item.redeemed ? 'Resgatado' : 'Não resgatado',
      }))

      const _consolidation = {
        total: consolidation.total,
        notRedeemed: consolidation.notRedeemed,
        redeemed: consolidation.redeemed,
      }

      const responseObject = {
        tableData: formattedResponseData,
        consolidatedData: [_consolidation],
      }

      // @ts-ignore
      setData(responseObject)
    }
    setLoading(false)
  }

  useEffect(() => {
    doFetch()
  },        [dateTo, dateFrom, redeemed])

  return (
    <Permission roles={[PARTNER_MANAGER]}>
      <Wrapper>
        <RowFilter>
          <InnerRow>
            <DatePicker
              format={'DD/MM/YYYY'}
              label="De"
              value={dateFrom}
              onChange={date => setDateFrom(date)}
            />
            <DatePicker
              format={'DD/MM/YYYY'}
              label="Até"
              value={dateTo}
              onChange={date => setDateTo(date)}
            />
            <DropDownPartners
              style={{ width: '200px' }}
              label="Unidade de troca"
              value={redeemed}
              setValue={setRedeemed}
              arrayData={redeemedChoices}
            />
          </InnerRow>
        </RowFilter>
        <Title text="Relatórios  de cupons" />
        {loading ? <Loader /> : data ? <CouponsReports data={data} /> : null}
      </Wrapper>
    </Permission>
  )
}
