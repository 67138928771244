const phoneMask = input => {
  let formattedInput = input.replace(/\D/g, '')
  formattedInput = formattedInput.replace(/^0/, '')
  if (formattedInput.length > 10) {
    formattedInput = formattedInput.replace(
      /^(\d\d)(\d{5})(\d{4}).*/,
      '($1) $2-$3',
    )
  } else if (formattedInput.length > 5) {
    formattedInput = formattedInput.replace(
      /^(\d\d)(\d{4})(\d{0,4}).*/,
      '($1) $2-$3',
    )
  } else if (formattedInput.length > 2) {
    formattedInput = formattedInput.replace(/^(\d\d)(\d{0,5})/, '($1) $2')
  } else {
    formattedInput = formattedInput.replace(/^(\d*)/, '($1')
  }

  return formattedInput
}

export default phoneMask
