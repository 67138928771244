import arrayToObject from './arrayToObject'
import not from './not'
import isEmpty from './isEmpty'

export default (props, formatter) =>
  not(isEmpty(props.data))
    ? {
      ...props,
      data: (() =>
          arrayToObject(
            Object.keys(props.data).map(key => formatter(props.data[key])),
            'id',
          ))(),
    }
    : props
