import { useState } from 'react'
import createUseContext from '@lib/createUseContext'
import actions from './actions'
import selectors from './selectors'
import providerReactAdmin from './provider-react-admin'

export interface User {
  authorization: string
  id: string
  role: string
  chainId: string
  placeId: string
}

export interface Auth {
  user?: User
}

const AuthStore = createUseContext(() => {
  const [$auth, setAuth] = useState<Auth>({
    user: undefined,
  })

  const authProviderReactAdmin = providerReactAdmin({
    ...selectors($auth),
    ...actions({ setAuth }),
  })

  return {
    $auth,
    authProviderReactAdmin,
    ...actions({ setAuth }),
    ...selectors($auth),
  }
})

export const useAuth = () => AuthStore()
export const AuthContext = AuthStore.Context
export const AuthProvider = AuthStore.Provider
