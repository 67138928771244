import getAll from './getAll'
import getOne from './getOne'
import create from './create'
import update from './update'
import destroy from './delete'
import getMany from './getMany'
import deleteMany from './deleteMany'

export default (type, resourceName, params) => {
  const actions = {
    GET_LIST: getAll,
    GET_ONE: getOne,
    GET_MANY: getMany,
    CREATE: create,
    UPDATE: update,
    DELETE: destroy,
    DELETE_MANY: deleteMany,
  }

  return actions[type] ? actions[type](resourceName, params) : void 0
}
