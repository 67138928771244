import React from 'react'
import { List, Datagrid, TextField } from 'react-admin'
import { PARTNER_ROUTES } from '@/constants'
import { redirectTo } from '@lib'
import { CustomActionBar, NewButton } from '../../components/PageComponents'

const PartnerActions = props => (
  <CustomActionBar {...props}>
    <NewButton onClick={() => redirectTo(PARTNER_ROUTES.CREATE)} />
  </CustomActionBar>
)

const customRowClick = id => redirectTo(PARTNER_ROUTES.EDIT(id))

export default props => (
  <List
    {...props}
    actions={<PartnerActions />}
    exporter={false}
    bulkActionButtons={false}
    title="Parceiros"
  >
    <Datagrid rowClick={customRowClick}>
      <TextField source="name" label="Nome" />
      <TextField source="city" label="Cidade" />
      <TextField source="state" label="Estado" />
      <TextField source="cep" label="CEP" />
      <TextField source="neighbourhood" label="Bairro" />
      <TextField source="street" label="Rua" />
      <TextField source="streetNumber" label="Número" />
      <TextField source="cnpj" label="CNPJ" />
      <TextField source="phone" label="Telefone" />
      <TextField source="contact" label="Contato" />
    </Datagrid>
  </List>
)
