import React from 'react'
import {
  List,
  Filter,
  Datagrid,
  TextField,
  TextInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
} from 'react-admin'
import { PERMISSION_TYPE, PermissionType } from '@/constants'
import hasPermission from '@lib/hasPermission'

const { ADMIN } = PERMISSION_TYPE

const allowedRoles = [ADMIN] as PermissionType

const UserFilter = props => (
  <Filter {...props}>
    <TextInput label="Pesquisar" source="search" alwaysOn />
    {hasPermission(allowedRoles) && (
      <ReferenceInput
        label="Rede"
        source="chainId"
        reference="_chains"
        allowEmpty
        alwaysOn
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    )}
  </Filter>
)

export default props => (
  <List
    {...props}
    exporter={false}
    bulkActionButtons={false}
    title="Usuários da rede"
    filters={<UserFilter />}
  >
    <Datagrid rowClick="edit">
      {hasPermission(allowedRoles) && (
        <ReferenceField
          source="chainId"
          reference="_chains"
          label="Rede"
          linkType={false}
          allowEmpty
          addLabel
        >
          <TextField source="name" />
        </ReferenceField>
      )}
      <TextField source="name" label="Nome" />
      <TextField source="login" label="Login" />
      <TextField source="role" label="Tipo de usuário" />
      <TextField source="maxPoints" label="Pontos por CPF" />
    </Datagrid>
  </List>
)
