import { PermissionType, USER_KEY, JWT_TOKEN } from '@/constants'
import { User } from '@store'

export default (roles: PermissionType) => {
  if (!roles) {
    return false
  }

  const user: User = JSON.parse(localStorage.getItem(USER_KEY) || '{}')

  if (!user.hasOwnProperty('role')) {
    localStorage.removeItem(JWT_TOKEN)
  }

  return roles.find((_role) => {
    const isPartner = Boolean(user.placeId)
    const basePermission = isPartner ? 'partner-' : 'chain-'
    const isAdmin = _role === 'admin'
    const isCashier = _role === 'cashier'
    const myReturn = (isAdmin || isCashier ? '' : basePermission) + user.role === _role
    return myReturn
  })
}
