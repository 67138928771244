import Store from '@material-ui/icons/Store'
import { PERMISSION_TYPE } from '@/constants'
import list from './PartnerList'
import create from './CreatePartner'
import edit from './EditPartner'

const { CHAIN_MANAGER, CHAIN_OPERATOR } = PERMISSION_TYPE

export default {
  list,
  create,
  edit,
  name: 'chains/id/partners',
  permission: [CHAIN_MANAGER, CHAIN_OPERATOR],
  options: { label: 'Parceiros' },
  icon: Store,
}
