import { useState, useEffect } from 'react'
import to from './to'
import * as request from '@lib/request'

export const method = {
  POST: 'post',
  GET: 'get',
  PUT: 'put',
  DELETE: 'destroy',
}

export default ({
  method = 'get',
  payload = undefined,
  url,
  callback = (data: any) => {},
  initialState,
  condition = () => true,
}) => {
  const [data, setData] = useState(initialState)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isUpdated, setUpdated] = useState(false)

  const didUnmount = () => {
    return
  }

  const didUpdate = () => {
    const fetchData = async () => {
      setIsError(false)
      setIsLoading(true)

      const [err, data] = await to(request[method](url, payload))
      if (err) {
        setIsError(true)
      }
      setData(data)
      callback(data)
      setIsLoading(false)
    }

    if (condition()) {
      fetchData()
    }
    return didUnmount
  }

  const doUpdate = args => {
    setUpdated(!isUpdated)
    return args
  }

  useEffect(didUpdate, [isUpdated])

  return [data, isLoading, isError, doUpdate]
}
