import { to, destroy } from '@lib'
import { DEFAULT_API_ADMIN } from '@/constants'
import { getChainId } from '@lib'

export default async (resourceName, params) => {
  const mapPath = {
    _chains: 'chains',
  }
  const path = mapPath[resourceName]

  let URL
  if (resourceName === 'money-products') {
    URL = `${DEFAULT_API_ADMIN}/chains/${getChainId()}/products`
  } else {
    URL = `${DEFAULT_API_ADMIN}/${
      path ? path : (resourceName || '').replace('id', getChainId())
    }`
  }

  await Promise.all(params.ids.map(async id => await destroy(`${URL}/${id}`)))

  // Necessary workaround due to React Admin issue
  return { data: [] }
}
