import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  FileInput,
  FileField,
  SelectInput,
  required,
  TabbedForm,
  FormTab,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
} from 'react-admin'

import useFetch from '@lib/useFetch'
import { DEFAULT_API_ADMIN } from '@/constants'
import { getChainId } from '@lib'
import { withStyles } from '@material-ui/core/styles'

const editStyles = {
  placesInput: {
    '& p': {
      color: 'grey',
      'margin-right': '1em',
      'font-weight': 'bold',
    },
  },
}

const edit = withStyles(editStyles)(({ classes, ...props }) => {
  const [{ data }] = useFetch({
    url: `${DEFAULT_API_ADMIN}/places`,
    initialState: { data: [] },
  })

  const getChoices = () =>
    data
      .filter(item => item.chainId === getChainId())
      .map(({ id, name }) => ({ id, name }))
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }

        if (a.name > b.name) {
          return 1
        }

        return 0
      })

  const validate = [required()]

  return (
    <Edit {...props} title="Editar produto">
      <TabbedForm>
        <FormTab label="Produto">
          <TextInput source="name" label="Nome" validate={validate} />
          <TextInput
            source="description"
            label="Descrição"
            validate={validate}
          />
          <FileInput source="imagefile" label="Imagem" accept="image/*">
            <FileField source="image" label="Imagem" />
          </FileInput>
        </FormTab>
        <FormTab label="Resgate">
          <ArrayInput source="places" label="" className={classes.placesInput}>
            <FormDataConsumer>
              {({ formData: upperFormData, ...upperRest }) => {
                const disableRemove = upperFormData.places
                  ? upperFormData.places.length === 1
                  : false
                return (
                  <SimpleFormIterator
                    disableRemove={disableRemove}
                    {...upperRest}
                  >
                    <FormDataConsumer>
                      {({
                        formData,
                        scopedFormData,
                        getSource,
                        label,
                        ...rest
                      }) => {
                        const allPlaces = data ? getChoices() : []
                        const selectedPlacesIds = formData.places.map(
                          p => p.placeId,
                        )
                        const availablePlaces = allPlaces.filter(
                          ({ id }) =>
                            !selectedPlacesIds.includes(id) ||
                            (scopedFormData
                              ? scopedFormData.placeId === id
                              : true),
                        )

                        return (
                          <SelectInput
                            disableRemove={true}
                            source={getSource('placeId')}
                            choices={availablePlaces}
                            label="Unidade de troca"
                            {...rest}
                          />
                        )
                      }}
                    </FormDataConsumer>
                    <TextInput
                      source="price"
                      label="Valor em pontos"
                      validate={validate}
                    />
                  </SimpleFormIterator>
                )
              }}
            </FormDataConsumer>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
})

export default edit
