import Group from '@material-ui/icons/Group'
import { PERMISSION_TYPE } from '@/constants'
import list from './placesUsersList'
import create from './placesUsersCreate'
import edit from './placesUsersEdit'
const { CHAIN_MANAGER, CHAIN_OPERATOR } = PERMISSION_TYPE

export default {
  list,
  create,
  edit,
  name: '_employees',
  permission: [CHAIN_MANAGER, CHAIN_OPERATOR],
  options: { label: 'Usuários de locais' },
  icon: Group,
}