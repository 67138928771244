import React from 'react'
import { Admin, Resource } from 'react-admin'
import { Layout } from './containers'
import resources from './resources'
import { CommonProvider } from '@store/common'
import { useAuth, AuthProvider } from '@store/auth'
import { theme, Login } from './theme'
import '@assets/styles/index.scss'
import { hasPermission } from '@lib'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import MomentUtils from '@date-io/moment'
import dataProvider from '@store/providers/react-admin-data'
import portugueseMessages from 'ra-language-portuguese'
import CustomRoutes from './customRoutes';
import 'moment/locale/pt-br'
import { BrowserRouter as Router } from "react-router-dom"
import Menu from './containers/Menu'

const messages = {
  pt: portugueseMessages,
}
const i18nProvider = locale => messages[locale]

const WrapperAdmin = () => {
  const { authProviderReactAdmin } = useAuth()

  return (
    <Admin
      menu={Menu}
      customRoutes={CustomRoutes}
      loginPage={Login}
      theme={theme}
      appLayout={Layout}
      dataProvider={dataProvider}
      authProvider={authProviderReactAdmin}
      locale="pt"
      i18nProvider={i18nProvider}
    >
      {resources
        .filter(resource => hasPermission(resource.permission))
        .map(_resource => (
          <Resource {..._resource} key={_resource.name} />
        ))}
    </Admin>
  )
}

export default () => {
  return (
    <Router>
      <CommonProvider>
        <AuthProvider>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <WrapperAdmin />
          </MuiPickersUtilsProvider>
        </AuthProvider>
      </CommonProvider>
    </Router>
  )
}
