import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { stringify } from 'query-string'
import {
  Paper,
  Select,
  Button,
  MenuItem,
  InputLabel,
  FormControl,
} from '@material-ui/core'
import { showNotification } from 'react-admin'
import { Title } from 'react-admin'
import SaveIcon from '@material-ui/icons/Save'
import DeleteIcon from '@material-ui/icons/Delete'
import useFetch, { method } from '@lib/useFetch'
import { get, patch, destroy } from '@lib/request'
import { DEFAULT_API_ADMIN, STATION_ROUTES } from '@/constants'
import { to, redirectTo } from '@lib'
import { MaskCellPhone, MaskCNPJ, MaskCep } from '../../components'
import { FormInput } from '../../components/PageComponents'
import {
  StationFormStyles as style,
  parseFormData,
  checkEmptyFields,
  fetchCep,
  removeMask,
  parseFetchedStation,
} from '../../formLib'

const defaultState = { value: '', touched: false }

const CHAINS_FETCH_URL = `${DEFAULT_API_ADMIN}/chains?${stringify({
  order: JSON.stringify({
    id: 'DESC',
  }),
})}`

const GET_STATION_URL = id => `${DEFAULT_API_ADMIN}/stations/${id}`

const EditStation = ({ showNotification, match }) => {
  const [station, setStation] = useState({
    chainId: defaultState,
    name: defaultState,
    city: defaultState,
    state: defaultState,
    cep: defaultState,
    neighbourhood: defaultState,
    street: defaultState,
    streetNumber: defaultState,
    phone: defaultState,
    cnpj: defaultState,
    latitude: defaultState,
    longitude: defaultState,
  })

  useEffect(() => {
    const init = async () => {
      const url = GET_STATION_URL(match.params.id)
      const [err, resource] = await to(get(url))

      if (err) {
        showNotification('Item não encontrado!', 'warning')
        return
      }

      const parsed = parseFetchedStation(resource.data)

      setStation(parsed)
    }
    init()
  }, [])

  const [{ data: chains }] = useFetch({
    url: CHAINS_FETCH_URL,
    method: method.GET,
    initialState: { data: [] },
  })

  const submitForm = async value => {
    const url = GET_STATION_URL(match.params.id)

    const [err] = await to(patch(url, value))

    if (err) {
      showNotification(err.message, 'warning')
      return
    }
    showNotification('Item atualizado com sucesso!')
    redirectTo(STATION_ROUTES.LIST)
  }

  const handleChange = field => e => {
    setStation({
      ...station,
      [field]: { value: e.target.value, touched: true },
    })
  }

  const handleCep = async event => {
    const {
      target: { value },
    } = event
    setStation({ ...station, cep: { value, touched: true } })

    if (removeMask(value).length === 8) {
      const errorMessage = await fetchCep(value, station, setStation)
      errorMessage && showNotification(errorMessage, 'warning')
    }
  }

  const handleSubmit = async () => {
    if (checkEmptyFields(station, setStation)) {
      showNotification(
        'Este formulário não está válido. Certifique-se de corrigir os erros!',
        'warning',
      )
      return
    }
    const formData = parseFormData(station)
    await submitForm(formData)
  }

  const handleDelete = async () => {
    const url = GET_STATION_URL(match.params.id)

    try {
      await to(destroy(url))
      showNotification('Item removido com sucesso!')
      redirectTo(STATION_ROUTES.LIST)
    } catch ({ message }) {
      showNotification(message, 'warning')
    }
  }

  const checkCep = station.cep.value.trim().length !== 10

  return (
    <Paper style={style.PaperStyle}>
      <Title title="Editar posto de combustível" />
      <FormControl style={style.FormControlStyle}>
        <InputLabel htmlFor="chain">Rede</InputLabel>
        <Select
          value={station.chainId.value}
          onChange={handleChange('chainId')}
        >
          <MenuItem value=""></MenuItem>
          {chains.map(chain => (
            <MenuItem value={chain.id} key={chain.id}>
              {chain.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormInput
        label="Nome *"
        field={station.name}
        onChange={handleChange('name')}
        isRequired
      />
      <FormInput
        label="CEP *"
        field={station.cep}
        onChange={e => handleCep(e)}
        isRequired
        inputComponent={MaskCep}
      />
      <FormInput
        label="Rua *"
        field={station.street}
        onChange={handleChange('street')}
        isRequired
        disabled={checkCep}
      />
      <FormInput
        label="Número *"
        field={station.streetNumber}
        onChange={handleChange('streetNumber')}
        isRequired
      />
      <FormInput
        label="Bairro *"
        field={station.neighbourhood}
        onChange={handleChange('neighbourhood')}
        isRequired
        disabled={checkCep}
      />
      <FormInput
        label="Cidade *"
        field={station.city}
        onChange={handleChange('city')}
        isRequired
        disabled={checkCep}
      />
      <FormInput
        label="Estado *"
        field={station.state}
        onChange={handleChange('state')}
        isRequired
        disabled={checkCep}
        inputProps={{ maxLength: 2 }}
        aditionalValidation={station.state.value.length < 2}
      />
      <FormInput
        label="Telefone *"
        field={station.phone}
        onChange={handleChange('phone')}
        isRequired
        inputComponent={MaskCellPhone}
      />
      <FormInput
        label="CNPJ *"
        field={station.cnpj}
        onChange={handleChange('cnpj')}
        isRequired
        inputComponent={MaskCNPJ}
      />
      <FormInput
        label="Latitude *"
        field={station.latitude}
        onChange={handleChange('latitude')}
        isRequired
      />
      <FormInput
        label="Longitude *"
        field={station.longitude}
        onChange={handleChange('longitude')}
        isRequired
      />
      <div style={style.ButtonsWrapper}>
        <Button variant="contained" color="secondary" onClick={handleSubmit}>
          <SaveIcon style={style.IconStyle} />
          Salvar
        </Button>
        <Button
          color="primary"
          onClick={handleDelete}
          style={style.DeleteIconStyle}
        >
          <DeleteIcon style={style.IconStyle} />
          Deletar
        </Button>
      </div>
    </Paper>
  )
}

export default connect(
  null,
  { showNotification },
)(EditStation)
