import React, { useState, useEffect } from 'react'
import { PERMISSION_TYPE, DEFAULT_API_ADMIN } from '@/constants'
import hasPermission from '@lib/hasPermission'

import { Wrapper, RowDate, Loader, Title, SimpleText } from '@components'
import { DatePicker } from "material-ui-pickers";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'

import moment from 'moment'
import { to, getChainId, get } from '@lib'

const { CHAIN_MANAGER, CHAIN_OPERATOR } = PERMISSION_TYPE

const Permission = ({ roles, ...props }) => hasPermission(roles) ? (<div {...props} />) : null

const PointsReport = ({ points }) => (
    <div>
        <Title text="Relatórios pontos gerados x pontos resgatados" />
        <SimpleText text={`Quantidade de pontos gerados: ${points && points.generatedPoints}`} />
        <SimpleText text={`Quantidade de pontos reagatados: ${points && -(points.redeemedPoints)}`} />
        <SimpleText text={`Quantidade de cupons gerados: ${points && points.couponCount}`} />
        <BarChar generatedPoints={points ? points.generatedPoints : 0} redeemedPoints={points ? -(points.redeemedPoints) : 0} />
    </div>
)

const BarChar = ({ generatedPoints, redeemedPoints }) => {
    const values = [
        { name: 'GERADOS', pontos: generatedPoints },
        { name: 'RESGATADOS', pontos: redeemedPoints, },

    ];
    return (
        <div style={{ marginTop: '30px' }}>
            <BarChart width={1000} height={300} data={values} layout="vertical">
                <CartesianGrid strokeDasharray="2 2" />
                <XAxis type="number" />
                <YAxis dataKey="name" type="category" angle={-90} dx={-10} dy={-45} />
                <Tooltip />
                <Legend />
                <Bar dataKey="pontos" fill="#ffdc73" />
            </BarChart>
        </div>

    )
}

export default props => {
    const [dateFrom, setDateFrom] = useState(moment().subtract(30, 'days'))
    const [dateTo, setDateTo] = useState(moment())
    const [points, setPoints] = useState(null)
    const [loading, setLoading] = useState(true)

    const doFetch = async () => {
        setLoading(true)
        const filter = {
            from: moment(dateFrom).format('YYYY-MM-DD'),
            to: moment(dateTo).format('YYYY-MM-DD'),
        }
        const URL = `${DEFAULT_API_ADMIN}/chains/${getChainId()}/reports/generated-points?filter=${JSON.stringify(filter)}`
        const [error, response] = await to(get(URL))
        if (error) {

        }
        setPoints(response)
        setLoading(false)
    }

    useEffect(() => { doFetch() }, [dateFrom, dateTo]);

    return (
        <Permission roles={[CHAIN_MANAGER, CHAIN_OPERATOR]}>
            <Wrapper>
                <RowDate>
                    <DatePicker
                        format={'DD/MM/YYYY'}
                        label="De"
                        value={dateFrom}
                        onChange={date => setDateFrom(date)}
                    />
                    <DatePicker
                        format={'DD/MM/YYYY'}
                        label="Até"
                        value={dateTo}
                        onChange={date => setDateTo(date)}
                    />
                </RowDate>
                {
                    loading
                        ? <Loader />
                        : <PointsReport points={points} />
                }

            </Wrapper>
        </Permission>
    )
}
