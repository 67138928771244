import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {
  Button,
  TextInput,
  SimpleForm,
  Toolbar,
  SaveButton,
  showNotification,
  required,
  regex,
} from 'react-admin'

import styles from './CouponSearch.module.scss'
import { formatCpf } from '@lib'
import { get, put } from '@lib/request'
import { DEFAULT_API_ADMIN, GUID_REGEX } from '@/constants'

interface ICoupon {
  id: string
  createdAt: string
  product: {
    id: string
    name: string
    description: string
    price: number
    imagePath: string
  }
  transaction: {
    customer: {
      name: string
      email: string
      cpf: string
    }
  }
}

const EMPTY_COUPON = {
  id: '',
  createdAt: '',
  product: {
    id: '',
    name: '',
    description: '',
    price: 0,
    imagePath: '',
  },
  transaction: {
    customer: {
      name: '',
      email: '',
      cpf: '',
    },
  },
}

const validate = [required(), regex(GUID_REGEX, 'Deve ser uma GUID válida.')];

const CustomToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        label="Buscar"
        submitOnEnter={true}
      />
    </Toolbar>
  )
}

const CouponSearch = ({ showNotification }) => {
  const [coupon, setCoupon] = useState<ICoupon>(EMPTY_COUPON)

  const executeSearch = async ({ id }) => {
    setCoupon(EMPTY_COUPON)

    try {
      const { data } = await get(`${DEFAULT_API_ADMIN}/coupons/${id}`)
      setCoupon(data)
    } catch ({ message }) {
      showNotification(message, 'warning')
    }
  }

  const redeemCoupon = async id => {
    try {
      await put(`${DEFAULT_API_ADMIN}/coupons/${id}`, {})
      showNotification('Cupom resgatado com sucesso!')
      setCoupon(EMPTY_COUPON)
    } catch ({ message }) {
      showNotification(message, 'warning')
    }
  };

  const { transaction: { customer } } = coupon

  return (
    <Fragment>
      <SimpleForm
        toolbar={<CustomToolbar />}
        save={executeSearch}
      >
        <TextInput
          fullWidth
          source="id"
          label="Digite o identificador do cupom..."
          validate={validate}
        />
      </SimpleForm>
      {Boolean(coupon.id.length) && (
        <div className={styles.container}>
          <div className={styles.productImage}>
            <img src={coupon.product.imagePath} />
          </div>
          <div className={styles.couponInfo}>
            <div>
              <span className={styles.info}>
                <b>Cupom: </b>
                <i>{coupon.id}</i>
              </span>
              <span className={styles.info}>
                <b>Data: </b>
                {moment(coupon.createdAt).format('DD/MM/YYYY')}
              </span>
            </div>
            <div>
              <span className={styles.info}>
                <b>Produto: </b>
                {coupon.product.name}
              </span>
              <span className={styles.info}>
                <b>Valor: </b>
                {`${coupon.product.price} pontos`}
              </span>
            </div>
            <span className={styles.info}>
              <b>Descrição: </b>
              {coupon.product.description}
            </span>
            <Button
              label="Resgatar"
              className={styles.submit}
              onClick={() => redeemCoupon(coupon.id)}
            />
          </div>
          <div className={styles.customerInfo}>
          <span className={styles.info}>
              <b>Cliente que efetuou a troca: </b>
            </span>
            <span className={styles.info}>
              <b>Nome: </b>
              {`${customer.name} (${customer.email})`}
            </span>
            <span className={styles.info}>
              <b>CPF: </b>
              {formatCpf(customer.cpf)}
            </span>
            <span className={styles.info}>
              <b>Data de Troca: </b>
              {moment(coupon.createdAt).format('DD/MM/YYYY - HH:mm')}
            </span>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default connect(null, {
  showNotification,
})(CouponSearch);
