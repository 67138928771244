import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  FileInput,
  FileField,
  Toolbar,
  SaveButton,
} from 'react-admin'
import { MaskCellPhone } from '../../components'

const PostEditToolbar = props => (
  <Toolbar {...props} >
      <SaveButton />
  </Toolbar>
);

export default props => (
  <Edit {...props} title="Editar rede">
    <SimpleForm toolbar={<PostEditToolbar />}>
      <TextInput source="name" label="Nome da rede/posto" />
      <TextInput
        source="phone"
        label="Telefone"
        InputProps={{
          inputComponent: MaskCellPhone,
        }}
      />
      <TextInput source="email" label="Email" />
      <TextInput source="contact" label="Contato" />
      <TextInput source="priority" label="Prioridade" type="number" />
      <FileInput source="logo" label="Logo" accept="image/*">
        <FileField source="logo" title="Logo" />
      </FileInput>
    </SimpleForm>
  </Edit>
)
