import isEqual from './isEqual'

export default (val: any) => {
  if (val === undefined) return true

  if (
    isEqual(typeof val, 'function') ||
    isEqual(typeof val, 'number') ||
    isEqual(typeof val, 'boolean') ||
    Object.prototype.toString.call(val) === '[object Date]'
  ) {
    return false
  }

  if (isEqual(val, null) || isEqual(val.length, 0)) {
    return true
  }

  if (isEqual(typeof val, 'object')) {
    return !Object.values(val).length
  }

  return false
}
