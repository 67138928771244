import { DEFAULT_API_ADMIN } from '@/constants'
import { get } from '@lib'
import { getChainId } from '@lib'


export default async (resourceName, params) => {
  const mapPath = {
    '_chains': 'chains',
  }
  const path = mapPath[resourceName]

  const URL = `${DEFAULT_API_ADMIN}/${path ? path : (resourceName || '').replace('id', getChainId())}`
  const res = (await Promise.all(params.ids.map((id) => get(`${URL}/${id}`)))).map((_res: any) => _res.data)

  return { data: res }
}
