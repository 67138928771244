import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceInput,
  SelectInput,
  required,
  minLength
} from 'react-admin'
import hasPermission from '@lib/hasPermission';
import { PERMISSION_TYPE } from '@/constants';

const { ADMIN } = PERMISSION_TYPE

const Permission = ({ roles, children, ...props }) => hasPermission(roles) ? (children(props)) : null


const validate = [required()];
const validatePassword = [required(), minLength(6)];

export default props => (
  <Create {...props} title="Cadastrar novo usuário">
    <SimpleForm>
      <Permission roles={[ADMIN]}>
        {props => (
          <ReferenceInput {...props} label="Rede" source="chainId" reference="_chains" allowEmpty >
            <SelectInput optionText="name" />
          </ReferenceInput>
        )}
      </Permission>
      <TextInput source="name" label="Nome" />
      <TextInput source="login" label="Login" validate={validate} />
      <TextInput source="password" label="Senha" validate={validatePassword} />
      <NumberInput source="maxPoints" label="Pontos por CPF" validate={validate} />
      <RadioButtonGroupInput label="Tipo de usuário" source="role" choices={[
        { id: 'manager', name: 'Rede administrador geral' },
        { id: 'operator', name: 'Rede operador' },
      ]} validate={validate} />
    </SimpleForm>
  </Create>
)
