import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  SaveButton,
  SimpleForm,
  NumberInput,
  showNotification,
  number,
  required,
  minValue,
} from 'react-admin'
import { submit } from 'redux-form'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

import { getChainId } from '@lib'
import { post } from '@lib/request'
import { DEFAULT_API_ADMIN } from '@/constants'

const VALUE_VALIDATION = [required(), number(), minValue(0)]

const BalanceButton = ({ clientId, submitForm }) => {
  const [displayModal, setDisplayModal] = useState(false)

  const addBalance = async ({ value }) => {
    const url = `${DEFAULT_API_ADMIN}/chains/${getChainId()}/customers/${clientId}/balance`

    setDisplayModal(false)

    try {
      await post(url, { value })
      showNotification('Pontos adicionados!')
    } catch ({ message }) {
      showNotification(message, 'warning')
    }
  }

  const handleSave = () => {
    submitForm('customer-add-balance')
  }

  return (
    <Fragment>
      <div style={{ marginTop: '1em' }}>
        <Button
          variant="outlined"
          type=""
          label="Adicionar Pontos"
          onClick={() => setDisplayModal(true)}
        />
      </div>
      <Dialog
        fullWidth
        open={displayModal}
        onClose={() => setDisplayModal(false)}
        aria-label="Add balance to customer"
      >
        <DialogTitle>{'Adicionar pontos para o cliente'}</DialogTitle>
        <DialogContent>
          <SimpleForm
            form="customer-add-balance"
            onSubmit={addBalance}
            toolbar={null}
          >
            <NumberInput
              autoFocus
              step={1}
              label="Digite o valor"
              source="value"
              validate={VALUE_VALIDATION}
            />
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          <SaveButton onClick={handleSave} />
          <Button label="Cancelar" onClick={() => setDisplayModal(false)} />
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default connect(
  null,
  {
    submitForm: submit,
    showNotification,
  },
)(BalanceButton)
