import {
  DEFAULT_API_ADMIN,
  MONEY_PRIZE_DESCRIPTION,
  MONEY_PRODUCTS,
  PRODUCT,
  clearMaskRoutes,
  chainIdDelete,
  routesWithUpload,
  mapPath,
} from '@/constants'
import {
  getChainId,
  clearMask,
  addChainId,
  to,
  post,
  makePayloadWithFile,
} from '@lib'

const handleMoneyProductsCreation = payload => {
  const _payload = {
    ...payload,
    isCash: true,
    description: MONEY_PRIZE_DESCRIPTION,
  }

  const body = new FormData()

  delete _payload['defaultValue']

  Object.keys(_payload).map(key => {
    const value = _payload[key]
    if (Array.isArray(value)) {
      body.append(key, JSON.stringify(value))
    } else {
      body.append(key, value)
    }
  })

  return body
}

const handlePayload = (resourceName, data) => {
  const CLEAR_MASK = clearMaskRoutes.includes(resourceName)
  const HAS_CHAIN_ID = !chainIdDelete.includes(resourceName)
  const HAS_UPLOAD_FILE = routesWithUpload.includes(resourceName)

  let payload = data

  if (resourceName === PRODUCT) {
    payload = {
      ...payload,
      places: payload.places.map(({ placeId, price }) => ({ placeId, price })),
      isCash: false,
    }
    delete payload['defaultValue']
  }

  if (CLEAR_MASK) {
    payload = clearMask(payload, ['phone', 'cnpj'])
  }

  if (HAS_CHAIN_ID) {
    payload = addChainId(payload, resourceName)
  }

  if (HAS_UPLOAD_FILE) {
    payload = makePayloadWithFile(payload, ['logo', 'image', 'imagefile'])
  }

  if (resourceName === MONEY_PRODUCTS) {
    payload = handleMoneyProductsCreation(payload)
  }

  return payload
}

export default async (resourceName, { data }) => {
  const path = mapPath[resourceName]
  const URL = `${DEFAULT_API_ADMIN}/${(path || resourceName || '').replace(
    'id',
    getChainId(),
  )}`

  const payload = handlePayload(resourceName, data)

  const [error, resource] = await to(post(URL, payload))

  // @TODO Add react-admin error treatment
  if (error) {
    console.log(error)
  }

  return resource
}
