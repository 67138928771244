import React from 'react'
import {
  List,
  Filter,
  Datagrid,
  TextField,
  TextInput,
} from 'react-admin'

import { reactAdminFormatter, formatCpf, formatPhone } from '@lib'

const ClientFilter = props => (
  <Filter {...props}>
    <TextInput label="Pesquisar" source="search" alwaysOn />
  </Filter>
)

const formatter = item => ({
  ...item,
  cpf: formatCpf(item.cpf),
  cellphone: formatPhone(item.cellphone),
})

const ClientsList = props => (
  <Datagrid {...reactAdminFormatter(props, formatter)} rowClick="edit">
    <TextField source="name" label="Nome" />
    <TextField source="cpf" label="CPF" />
    <TextField source="email" label="Email" />
    <TextField source="cellphone" label="Telefone" />
  </Datagrid>
)

export default props => {
  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      title="Clientes da rede"
      filters={<ClientFilter />}
    >
      <ClientsList />
    </List>
  )
}
