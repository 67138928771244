import { useState } from 'react'
import createUseContext from '@lib/createUseContext'
import actions from './actions'
import selectors from './selectors'

export interface Common {
}

const initialState: Common = {
}

const CommonStore = createUseContext(() => {
  const [$common, setCommon] = useState(initialState)

  return { $common, ...actions(setCommon), ...selectors($common) }
})

export const useCommon = () => CommonStore()
export const CommonContext = CommonStore.Context
export const CommonProvider = CommonStore.Provider
