import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  Filter,
  ReferenceInput,
  SelectInput,
} from 'react-admin'
import { STATION_ROUTES } from '@/constants'
import { redirectTo } from '@lib'
import { CustomActionBar, NewButton } from '../../components/PageComponents'

const Filters = props => (
  <Filter {...props}>
    <ReferenceInput
      label="Rede"
      source="chainId"
      reference="_chains"
      allowEmpty
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
)

const StationActions = props => (
  <CustomActionBar {...props}>
    <NewButton onClick={() => redirectTo(STATION_ROUTES.CREATE)} />
  </CustomActionBar>
)

const customRowClick = id => redirectTo(STATION_ROUTES.EDIT(id))

export default props => (
  <List
    {...props}
    actions={<StationActions />}
    exporter={false}
    bulkActionButtons={false}
    filters={<Filters />}
    title="Posto de Combustível"
  >
    <Datagrid rowClick={customRowClick}>
      <ReferenceField
        label="Rede"
        reference="_chains"
        source="chainId"
        linkType={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" label="Nome" />
      <TextField source="city" label="Cidade" />
      <TextField source="state" label="Estado" />
      <TextField source="cep" label="CEP" />
      <TextField source="neighbourhood" label="Bairro" />
      <TextField source="street" label="Rua" />
      <TextField source="streetNumber" label="Número" />
      <TextField source="phone" label="Telefone" />
      <TextField source="cnpj" label="CNPJ" />
    </Datagrid>
  </List>
)
