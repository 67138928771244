import { createMuiTheme } from '@material-ui/core'

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#e65017',
      light: '#ffa464',
      dark: '#b62100',
      A100: '#CDC2D4',
      A200: '#AB99B8',
      A400: '#705285',
      A700: '#46275D',
    },
    secondary: {
      main: '#e65017',
      light: '#ffa464',
      dark: '#b62100',
      A100: '#CDC2D4',
      A200: '#AB99B8',
      A400: '#705285',
      A700: '#46275D',
    },
  },
})

export { default as Login } from './Login'
