import React, { useState, useEffect } from 'react'
import { PERMISSION_TYPE, DEFAULT_API_ADMIN } from '@/constants'
import hasPermission from '@lib/hasPermission'

import {
  Wrapper,
  RowDate,
  Loader,
  Title,
  RowContainer,
  TableGeneric,
} from '@components'
import { DatePicker } from 'material-ui-pickers'

import moment from 'moment'
import { to, getChainId, get } from '@lib'

const { CHAIN_MANAGER, CHAIN_OPERATOR } = PERMISSION_TYPE

const Permission = ({ roles, ...props }) =>
  hasPermission(roles) ? <div {...props} /> : null

const PointsReport = ({ points }) => (
  <RowContainer>
    <TableGeneric
      headCells={['Data/Hora', 'Úsuario', 'Quatidade de pontos']}
      arrayData={points.transactions}
      title="Transações"
      style={{ width: '48%' }}
    />
    <TableGeneric
      headCells={['Úsuario', 'Quatidade de pontos']}
      arrayData={points.pointsAddedPerEmployee}
      title="Pontos distribuidos por úsuario"
      style={{ width: '48%' }}
    />
  </RowContainer>
)

export default props => {
  const [dateFrom, setDateFrom] = useState(moment().subtract(90, 'days'))
  const [dateTo, setDateTo] = useState(moment())
  const [points, setPoints] = useState(null)
  const [loading, setLoading] = useState(true)

  const doFetch = async () => {
    setLoading(true)
    const filter = {
      from: moment(dateFrom).format('YYYY-MM-DD'),
      to: moment(dateTo).format('YYYY-MM-DD'),
    }
    const URL = `${DEFAULT_API_ADMIN}/chains/${getChainId()}/reports/added-points?filter=${JSON.stringify(
      filter,
    )}`
    const [error, response] = await to(get(URL))
    if (error) {
    }
    setPoints(response)
    setLoading(false)
  }

  useEffect(() => {
    doFetch()
  },        [dateFrom, dateTo])

  return (
    <Permission roles={[CHAIN_MANAGER, CHAIN_OPERATOR]}>
      <Wrapper>
        <RowDate>
          <DatePicker
            format={'DD/MM/YYYY'}
            label="De"
            value={dateFrom}
            onChange={date => setDateFrom(date)}
          />
          <DatePicker
            format={'DD/MM/YYYY'}
            label="Até"
            value={dateTo}
            onChange={date => setDateTo(date)}
          />
        </RowDate>
        <Title text="Relatórios pontos distribuidos" />
        {loading ? <Loader /> : <PointsReport points={points} />}
      </Wrapper>
    </Permission>
  )
}
