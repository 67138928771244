import { clearMask, isEmpty, to, not } from '@lib'
import cepProvider from '@store/providers/getCep'
import {
  CEP_TYPE_ERROR,
  CEP_ERROR_MESSAGE,
  CEP_NOT_FOUND_MESSAGE,
} from '@/constants'

export const parseFormData = data => {
  const parsed = Object.keys(data).reduce(
    (acc, field) =>
      !isEmpty(data[field].value)
        ? {
            ...acc,
            [field]: data[field].value,
          }
        : acc,
    {},
  )

  return clearMask(parsed, ['phone', 'cnpj', 'cep'])
}

export const parseFetchedPartner = data => {
  return {
    cep: { value: data.cep, touched: false },
    city: { value: data.city, touched: false },
    cnpj: { value: data.cnpj, touched: false },
    name: { value: data.name, touched: false },
    neighbourhood: { value: data.neighbourhood, touched: false },
    phone: { value: data.phone, touched: false },
    state: { value: data.state, touched: false },
    street: { value: data.street, touched: false },
    streetNumber: { value: data.streetNumber, touched: false },
    contact: { value: data.contact || '', touched: false, nullable: true },
  }
}

export const parseFetchedStation = data => {
  return {
    chainId: { value: data.chainId, touched: false },
    name: { value: data.name, touched: false },
    city: { value: data.city, touched: false },
    state: { value: data.state, touched: false },
    cep: { value: data.cep, touched: false },
    neighbourhood: { value: data.neighbourhood, touched: false },
    street: { value: data.street, touched: false },
    streetNumber: { value: data.streetNumber, touched: false },
    phone: { value: data.phone, touched: false },
    cnpj: { value: data.cnpj, touched: false },
    latitude: { value: data.latitude, touched: false },
    longitude: { value: data.longitude, touched: false },
  }
}

export const checkEmptyFields = (
  state: Object,
  changeState: Function,
): boolean => {
  const newState = { ...state }
  const hasEmpty = Object.keys(state).map(field => {
    if (
      not(state[field].nullable) &&
      (isEmpty(state[field].value) ||
        (field === 'state' && state[field].value.length < 2))
    ) {
      newState[field] = { ...newState[field], touched: true }

      return true
    }
    return false
  })
  changeState(newState)
  return hasEmpty.some(e => e)
}

export const removeMask = (value: string): string =>
  value.toString().replace(/\D+/g, '')

export const fetchCep = async (
  cep: string,
  state: Object,
  setState: Function,
): Promise<void | string> => {
  const [err, res] = await to(cepProvider(removeMask(cep)))

  if (err) {
    if (err.message === CEP_TYPE_ERROR) {
      return CEP_ERROR_MESSAGE
    }

    setState({
      ...state,
      cep: { value: cep, touched: true },
      city: { value: '', touched: true },
      state: { value: '', touched: true },
      neighbourhood: { value: '', touched: true },
      street: { value: '', touched: true },
    })

    return CEP_NOT_FOUND_MESSAGE
  }

  setState({
    ...state,
    cep: { value: cep, touched: true },
    city: { value: res.city, touched: true },
    state: { value: res.state, touched: true },
    neighbourhood: { value: res.neighborhood, touched: true },
    street: { value: res.street, touched: true },
  })
}
