import Contacts from '@material-ui/icons/Contacts'
import { PERMISSION_TYPE } from '@/constants'
import list from './ClientList'
import edit from './ClientEdit'

const { CHAIN_MANAGER, CHAIN_OPERATOR } = PERMISSION_TYPE

export default {
  list,
  edit,
  name: 'customers',
  permission: [CHAIN_MANAGER, CHAIN_OPERATOR],
  options: { label: 'Clientes' },
  icon: Contacts,
}