import { stringify } from 'query-string'

import { to, get, getChainId, getWithFilter } from '@lib'
import { DEFAULT_API_ADMIN, mapPath } from '@/constants'

const getResourcePath = (path, resourceName) => {
  return `${path ? path : (resourceName || '').replace('id', getChainId())}`
}

export default async (resourceName, params) => {
  const { page, perPage } = params.pagination
  const { field, order } = params.sort

  const path = mapPath[resourceName]
  const _params = getWithFilter(params, resourceName)
  const query = {
    order: JSON.stringify({ [field]: order }),
    skip: (page - 1) * perPage,
    take: perPage,
    filter: JSON.stringify(_params.filter),
  }

  const URL = `${DEFAULT_API_ADMIN}/${getResourcePath(
    path,
    resourceName,
  )}?${stringify(query)}`

  const [error, resource] = await to(get(URL))

  // @TODO Add react-admin error treatment
  if (error) {
    // @ts-ignore
    if (error.statusCode === 401) {
      localStorage.removeItem('token')
    }
    
    console.log(error)
  }

  console.log(resource)

  return resource
}
