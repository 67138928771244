import React from 'react'
import {
  List,
  Filter,
  Datagrid,
  TextField,
  TextInput,
  EmailField
} from 'react-admin'

const ChainFilter = props => (
  <Filter {...props}>
    <TextInput label="Pesquisar" source="search" alwaysOn />
  </Filter>
)

export default props => (
  <List {...props} exporter={false} bulkActionButtons={false} title="Redes" filters={<ChainFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="name" label="Nome da rede/posto" />
      <TextField source="phone" label="Telefone" />
      <EmailField source="email" label="Email" />
      <TextField source="contact" label="Contato" />
    </Datagrid>
  </List>
)
