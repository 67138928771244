import to from './to'
import { JWT_TOKEN } from '@/constants'
import { fetchUtils } from 'react-admin'

export type Methods = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'

const getHeaders = (isFormData = false) => {
  const jwtToken = localStorage.getItem(JWT_TOKEN)
  const Authorization = jwtToken ? `${jwtToken}` : ''
  const header = {
    Authorization,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }
  if (isFormData) {
    delete header['Content-Type']
  }

  return new Headers(header)
}

export const httpClientReactAdmin = (
  url: string,
  options: RequestInit = {},
) => {
  if (!options.headers) {
    options.headers = getHeaders()
  }
  return fetchUtils.fetchJson(url, options)
}

const resolveFetch = async (res: Response) => {
  const json = await res.json()
  if (!res.ok) {
    throw json
  }
  return json
}

const getConfig = (method, payload): RequestInit => {
  const isFormData = payload instanceof FormData
  const headers = getHeaders(isFormData)
  const body = isFormData ? payload : JSON.stringify(payload || '{}')
  const withBody = payload ? { body } : {}

  return {
    method,
    ...withBody,
    headers,
  }
}

export const request = async <T, P = unknown>(
  url: string,
  method: Methods,
  payload?: P,
) => {
  const [err, results] = await to(
    fetch(url, getConfig(method, payload)).then(resolveFetch),
  )

  if (err) {
    throw err
  }

  return results as T
}

export const get = <T>(url: string) => request<T>(url, 'GET')

export const post = <T>(url: string, payload: unknown) =>
  request<T>(url, 'POST', payload)

export const put = <T>(url: string, payload: unknown) =>
  request<T>(url, 'PUT', payload)

export const patch = <T>(url: string, payload: unknown) =>
  request<T>(url, 'PATCH', payload)

export const destroy = <T>(url: string) => request<T>(url, 'DELETE')
