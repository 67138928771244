import { to } from '@lib'
import cep from 'cep-promise'
import { CEP_TYPE_ERROR } from '@/constants'

export interface CEP {
  cep: string
  state: string
  city: string
  street: string
  neighborhood: string
}
interface CepError {
  message: string
  name: string
  type: string
  errors: {
    message: string
    name: string
    service: string
  }[]
}

export const parseCep = (data: CEP): CEP =>
  Object.keys(data).reduce(
    (acc, key) => ({ ...acc, [key]: data[key] || '' }),
    {} as CEP,
  )

export default async cepCode => {
  const [error, resource] = await to(cep(cepCode))

  if (error) {
    if (
      (error as CepError).errors.every(e => e.message.includes(CEP_TYPE_ERROR))
    ) {
      throw new Error(CEP_TYPE_ERROR)
    }

    throw error
  }

  return parseCep(resource)
}
