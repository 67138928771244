import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  FileField,
  FileInput,
  required,
  minLength,
  maxLength,
  email,
} from 'react-admin'
import { MaskCellPhone } from '../../components'

const validateName = [required(), minLength(2)]
const validateRequired = [required()]
const validateEmail = [required(), email()]

export default props => (
  <Create {...props} title="Cadastrar nova rede">
    <SimpleForm>
      <TextInput source="name" label="Nome da rede/posto" validate={validateName} />
      <TextInput
        source="phone"
        label="Telefone"
        validate={validateRequired}
        InputProps={{
          inputComponent: MaskCellPhone,
        }}
      />
      <TextInput source="email" label="Email" validate={validateEmail} />
      <TextInput source="contact" label="Contato" />
      <FileInput
        source="logo"
        label="Logo"
        accept="image/*"
        validate={validateRequired}
      >
        <FileField source="logo" title="Logo" />
      </FileInput>
      <TextInput source="priority" label="Prioridade" type="number" />
    </SimpleForm>
  </Create>
)
