export default (data, keysToUpload) => {
  const body = new FormData()
  const [keyToUpload] = Object.keys(data).filter(_key =>
    keysToUpload.find(_keyToUpload => _keyToUpload === _key),
  )
  const myFile = data[keyToUpload]
  delete data[keyToUpload]

  keyToUpload && body.append(keyToUpload, myFile.rawFile)

  Object.keys(data).map(key => {
    const value = data[key]
    if (Array.isArray(value)) {
      body.append(key, JSON.stringify(value))
    } else {
      body.append(key, value)
    }
  })

  return body
}
