import React, { useState, useEffect } from 'react'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'

import { PERMISSION_TYPE, DEFAULT_API_ADMIN } from '@/constants'
import hasPermission from '@lib/hasPermission'

import {
  Wrapper,
  RowFilter,
  InnerRow,
  Loader,
  TableGeneric,
  DropDownPartners,
} from '@components'
import { DatePicker } from 'material-ui-pickers'

import moment from 'moment'
import { to, getChainId, get } from '@lib'
import useFetch from '@lib/useFetch'

const { CHAIN_MANAGER, CHAIN_OPERATOR } = PERMISSION_TYPE

const Permission = ({ roles, ...props }) =>
  hasPermission(roles) ? <div {...props} /> : null

const ProductsReport = ({ products }) => (
  <div style={{ marginTop: '20px' }}>
    <TableGeneric
      headCells={['Descrição', 'Preço', 'Quantidade']}
      arrayData={products.products}
      title="Produtos resgatados"
      style={{ width: '100%' }}
    />
  </div>
)

export default props => {
  const [dateFrom, setDateFrom] = useState(moment().subtract(90, 'days'))
  const [dateTo, setDateTo] = useState(moment())
  const [products, setProducts] = useState(null)
  const [loading, setLoading] = useState(true)
  const [placeId, setPlaceId] = useState(null)

  const [{ data: locals }] = useFetch({
    url: `${DEFAULT_API_ADMIN}/places`,
    initialState: { data: [] },
  })

  const getChoices = () =>
    locals
      .filter(item => item.chainId === getChainId())
      .map(({ id, name }) => ({ id, name }))

  const doFetch = async () => {
    setLoading(true)
    const filter = {
      from: moment(dateFrom).format('YYYY-MM-DD'),
      to: moment(dateTo).format('YYYY-MM-DD'),
      placeId,
    }
    const URL = `${DEFAULT_API_ADMIN}/chains/${getChainId()}/reports/products?filter=${JSON.stringify(
      filter,
    )}`
    const [error, response] = await to(get(URL))
    if (error) {
    }
    setProducts(response)
    setLoading(false)
  }

  useEffect(() => {
    doFetch()
  }, [dateFrom, dateTo, placeId])

  return (
    <Permission roles={[CHAIN_MANAGER, CHAIN_OPERATOR]}>
      <Wrapper>
        <RowFilter>
          <InnerRow>
            <DatePicker
              format={'DD/MM/YYYY'}
              label="De"
              value={dateFrom}
              onChange={date => setDateFrom(date)}
            />
            <DatePicker
              format={'DD/MM/YYYY'}
              label="Até"
              value={dateTo}
              onChange={date => setDateTo(date)}
            />
            <DropDownPartners
              style={{ width: '200px' }}
              label="Unidade de troca"
              value={placeId}
              setValue={setPlaceId}
              arrayData={getChoices()}
            />
          </InnerRow>
          {placeId && (
            <IconButton onClick={() => setPlaceId(null)}>
              <ClearIcon />
            </IconButton>
          )}
        </RowFilter>
        {loading ? <Loader /> : <ProductsReport products={products} />}
      </Wrapper>
    </Permission>
  )
}
