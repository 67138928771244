import React from 'react'
import { addField, FieldTitle } from 'ra-core'
import { DatePicker } from 'material-ui-pickers'
import moment from 'moment'

const sanitizeRestProps = ({
  alwaysOn,
  basePath,
  component,
  defaultValue,
  formClassName,
  initializeForm,
  input,
  isRequired,
  label,
  limitChoicesToValue,
  locale,
  meta,
  options,
  optionText,
  optionValue,
  record,
  resource,
  allowEmpty,
  source,
  textAlign,
  translate,
  translateChoice,
  ...rest
}) => rest

export default addField(props => {
  const {
    className,
    meta,
    input,
    isRequired,
    label,
    options = {},
    source,
    resource,
    isEmpty,
    setRefDate,
    min,
    startDate,
    ...rest
  } = props
  if (typeof meta === 'undefined') {
    throw new Error(
      'https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component',
    )
  }

  const handleDateChange = date => {
    if (setRefDate) {
      setRefDate(date)
    }
    props.input.onChange(moment(date).format('YYYY-MM-DD'))
    props.input.onBlur()
  }

  if (isEmpty && moment(min) > moment(props.input.value)) {
    props.input.onChange('')
    props.input.onBlur()
  }

  const { touched, error } = meta

  if (!isEmpty && !props.input.value) {
    props.input.onChange(moment(new Date()).format('YYYY-MM-DD'))
  }

  return (
    <DatePicker
      style={{ marginTop: 16 }}
      error={!!(touched && error)}
      helperText={touched && error}
      id={`${resource}_${source}_date_input`}
      {...input}
      {...options}
      {...sanitizeRestProps(rest)}
      className={className}
      value={props.input.value}
      invalidLabel="Sem limite"
      label={
        <FieldTitle
          label={label}
          source={source}
          resource={resource}
          isRequired={isRequired}
        />
      }
      onChange={handleDateChange}
      format="DD/MM/YYYY"
      minDate={min}
    />
  )
})
