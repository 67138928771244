import React from 'react'
import {
  List,
  Filter,
  Datagrid,
  TextField,
  TextInput,
  BulkDeleteButton,
  ReferenceField,
} from 'react-admin'

import { reactAdminFormatter, formatReal } from '@lib'

const Filters = props => (
  <Filter {...props}>
    <TextInput label="Nome" source="search" />
  </Filter>
)

const BulkActionButtons = props => (
  <BulkDeleteButton {...props} label="Inativar" />
)

const formatter = item => ({
  ...item,
  name: formatReal(item.name),
})

const MoneyProductsList = props => (
  <Datagrid {...reactAdminFormatter(props, formatter)} rowClick="edit">
    <TextField source="name" label="Valor em reais" />
    <TextField source="description" label="Descrição" />
  </Datagrid>
)

export default props => (
  <List
    {...props}
    exporter={false}
    title="Produtos"
    filters={<Filters />}
    bulkActionButtons={<BulkActionButtons />}
  >
    <MoneyProductsList />
  </List>
)
