import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_ERROR } from 'react-admin'
import { JWT_TOKEN } from '@/constants'

export default ({ doLogin, doLogout }) => async (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params
    const response = await doLogin(username, password)

    if (response.error && response.statusCode === 401) {
      throw new Error('Usuários e senha incorretos!')
    }

    return response
  }

  if (type === AUTH_CHECK) {
    return localStorage.getItem(JWT_TOKEN)
      ? Promise.resolve()
      : Promise.reject()
  }

  if (type === AUTH_ERROR) {
    return Promise.resolve()
  }

  if (type === AUTH_LOGOUT) {
    return doLogout()
  }

  return Promise.reject('Unknown method')
}
