import React from 'react'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import AddIcon from '@material-ui/icons/Add'
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Input,
  FormHelperText,
  Button,
} from '@material-ui/core'
import { CardActions } from 'react-admin'
import { StationFormStyles as style } from '../../formLib'

import moment from 'moment'

const divStyle = {
  padding: '20px 20px 20px 20px',
}

const simpleTextStyle = {
  marginTop: '10px',
  fontSize: '15px',
}

const rowStyle = {
  alignSelf: 'center',
  justifyContent: 'space-between',
  display: 'flex',
  width: '450px',
}

const rowFilterStyle = {
  alignSelf: 'center',
  justifyContent: 'space-between',
  alignItems: 'center',
  display: 'flex',
  width: '70%',
}

const innerRowStyle = {
  alignSelf: 'center',
  justifyContent: 'space-between',
  display: 'flex',
  width: '93%',
}

const loaderStyle = {
  padding: '20px 20px 20px 20px',
  justifyContent: 'center',
  display: 'flex',
}

const titleStyle = {
  marginTop: '30px',
  fontSize: '18px',
  color: 'grey',
}

const menuTextStyle = {
  fontSize: '17px',
  marginLeft: '54px',
  paddingTop: '30px',
  paddingBottom: '10px',
  color: 'rgba(0, 0, 0, 0.54)',
}

const rowContainerStyle = {
  justifyContent: 'space-between',
  display: 'flex',
  marginTop: '20px',
}

const tableTitleStyle = {
  paddingTop: '10px',
  paddingLeft: '20px',
  fontSize: '13px',
}

export const Wrapper = props => (
  <CardContent>
    <Paper>
      <div style={divStyle}>{props.children}</div>
    </Paper>
  </CardContent>
)

export const SimpleText = ({ text }) => <p style={simpleTextStyle}>{text}</p>

export const RowDate = props => <div style={rowStyle}>{props.children}</div>

export const RowFilter = props => (
  <div style={rowFilterStyle}>{props.children}</div>
)

export const InnerRow = props => (
  <div style={innerRowStyle}>{props.children}</div>
)

export const Loader = props => (
  <div style={loaderStyle}>
    <CircularProgress />
  </div>
)

export const Title = ({ text }) => <p style={titleStyle}>{text}</p>

export const MenuText = ({ text }) => <p style={menuTextStyle}>{text}</p>

export const RowContainer = props => (
  <div style={rowContainerStyle}>{props.children}</div>
)

export const TableTitle = ({ text }) => <p style={tableTitleStyle}>{text}</p>

export const TableGeneric = ({ headCells, arrayData, title, style }) => (
  <Paper style={style}>
    <TableTitle text={title} />
    <Table>
      <TableHead>
        <TableRow>
          {headCells.map(cell => (
            <TableCell key={`${cell}-${Math.random()}`} align="right">
              {cell}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {arrayData.length > 0 ? (
          arrayData.map((row, index) => (
            <TableRow key={index}>
              {Object.keys(row).map(key => {
                if (key === 'created_at') {
                  return (
                    <TableCell key={key}>
                      {moment(row[key]).format('DD-MM-YYYY HH:mm')}
                    </TableCell>
                  )
                }
                return <TableCell key={key}>{row[key]}</TableCell>
              })}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell>Nenhum registro</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </Paper>
)

export const DropDownPartners = ({
  style,
  label,
  value,
  setValue,
  arrayData,
}) => (
  <FormControl style={style}>
    <InputLabel htmlFor="drop-down" shrink>
      {label}
    </InputLabel>
    <Select
      value={value}
      onChange={event => setValue(event.target.value)}
      inputProps={{
        name: 'drop-down',
        id: 'drop-down',
      }}
    >
      {arrayData
        ? arrayData.map(partner => (
            <MenuItem value={partner.id}>{partner.name}</MenuItem>
          ))
        : null}
    </Select>
  </FormControl>
)

const FormControlStyle = {
  marginTop: '16px',
  marginBottom: '8px',
  width: '256px',
}

interface FormInputProps {
  label: string
  isRequired?: boolean
  field: { value: string; touched: boolean }
  onChange(e: any): void
  inputComponent?: React.ComponentClass | React.FunctionComponent
  disabled?: boolean
  inputProps?: any
  aditionalValidation?: boolean
}

export const FormInput: React.FC<FormInputProps> = ({
  label,
  isRequired = false,
  field,
  onChange,
  inputComponent,
  disabled = false,
  inputProps = {},
  aditionalValidation = false,
}) => {
  const showError = () =>
    (isRequired && field.touched && field.value.length === 0) ||
    (field.touched && aditionalValidation)

  const handleChange = e => {
    onChange(e)
  }

  return (
    <FormControl style={style.FormControlStyle} error={showError()}>
      <InputLabel>{label}</InputLabel>
      <Input
        value={field.value}
        onChange={handleChange}
        inputComponent={inputComponent as React.ComponentClass}
        disabled={disabled}
        inputProps={inputProps}
      />
      {showError() && <FormHelperText>Obrigatório</FormHelperText>}
    </FormControl>
  )
}

export const CustomActionBar: any = ({
  children,
  bulkActions,
  basePath,
  displayedFilters,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    {children}
  </CardActions>
)

export const NewButton = ({ onClick }) => (
  <Button color="primary" onClick={onClick}>
    <AddIcon style={{ fontSize: '20px', marginRight: '8px' }} />
    Novo
  </Button>
)
