export const PaperStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  paddingTop: '16px',
  paddingLeft: '24px',
}

export const FormControlStyle = {
  marginTop: '16px',
  marginBottom: '8px',
  width: '256px',
}

export const IconStyle = {
  marginRight: '8px',
}

export const ButtonWrapper = {
  marginTop: '26px',
  marginBottom: '16px',
}

export const ButtonsWrapper = {
  marginTop: '26px',
  marginBottom: '16px',
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  paddingRight: '24px',
}

export const DeleteIconStyle = {
  color: '#f44336',
}
