import Redeem from '@material-ui/icons/Redeem'
import { PERMISSION_TYPE } from '@/constants'
import list from './ProductList'
import create from './ProductCreate'
import edit from './ProductEdit'

const { CHAIN_MANAGER, CHAIN_OPERATOR } = PERMISSION_TYPE

export default {
  list,
  create,
  edit,
  name: 'chains/id/products',
  permission: [CHAIN_MANAGER, CHAIN_OPERATOR],
  options: { label: 'Produtos' },
  icon: Redeem,
}
