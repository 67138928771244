import React, { createElement } from 'react'
import resources from '@resources'
import { connect } from 'react-redux'
import { MenuItemLink, getResources } from 'react-admin'
import { withRouter } from 'react-router-dom'
import LabelIcon from '@material-ui/icons/Label'
import hasPermission from '@lib/hasPermission'
import { PERMISSION_TYPE } from '@/constants'
import { MenuText } from '@components'

const { CHAIN_MANAGER, CHAIN_OPERATOR } = PERMISSION_TYPE

const Permission = ({ roles, ...props }) =>
  hasPermission(roles) ? <div {...props} /> : null

const Menu = ({ onMenuClick, logout }) => (
  <div>
    {resources
      .filter(
        resource =>
          hasPermission(resource.permission) && resource.name !== 'places',
      )
      .map(resource => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={
            (resource.options && resource.options.label) || resource.name
          }
          leftIcon={resource.icon && createElement(resource.icon)}
          onClick={onMenuClick}
        />
      ))}
    <Permission roles={[CHAIN_MANAGER, CHAIN_OPERATOR]}>
      <MenuText text="Relatórios" />
      <MenuItemLink
        to="/report-points"
        primaryText="Pontos"
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/report-added-points"
        primaryText="Pontos distribuídos"
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/report-unused-points"
        primaryText="Pontos não utilizados"
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/report-redemption"
        primaryText="Produtos resgatados"
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/report-coupons"
        primaryText="Cupons"
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
      />
    </Permission>
  </div>
)

const mapStateToProps = state => ({
  resources: getResources(state),
})

export default withRouter(connect(mapStateToProps)(Menu))
