import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Paper, Button } from '@material-ui/core'
import { showNotification } from 'react-admin'
import { Title } from 'react-admin'
import SaveIcon from '@material-ui/icons/Save'
import DeleteIcon from '@material-ui/icons/Delete'
import { get, patch, destroy } from '@lib/request'
import { DEFAULT_API_ADMIN, PARTNER_ROUTES } from '@/constants'
import { to, redirectTo, getChainId } from '@lib'
import { MaskCellPhone, MaskCNPJ, MaskCep } from '../../components'
import { FormInput } from '../../components/PageComponents'
import {
  PartnerFormStyles as style,
  parseFormData,
  checkEmptyFields,
  fetchCep,
  removeMask,
  parseFetchedPartner,
} from '../../formLib'

const defaultState = { value: '', touched: false }

const PARTNER_URL = id =>
  `${DEFAULT_API_ADMIN}/chains/${getChainId()}/partners/${id}`

const EditPartner = ({ showNotification, match }) => {
  const [partner, setPartner] = useState({
    cep: defaultState,
    city: defaultState,
    cnpj: defaultState,
    name: defaultState,
    neighbourhood: defaultState,
    phone: defaultState,
    state: defaultState,
    street: defaultState,
    streetNumber: defaultState,
    contact: { ...defaultState, nullable: true },
  })

  useEffect(() => {
    const init = async () => {
      const url = PARTNER_URL(match.params.id)
      const [err, resource] = await to(get(url))

      if (err) {
        showNotification('Item não encontrado!', 'warning')
        return
      }

      const parsed = parseFetchedPartner(resource.data)

      setPartner(parsed)
    }
    init()
  }, [])

  const submitForm = async value => {
    const url = PARTNER_URL(match.params.id)

    const [err] = await to(patch(url, value))

    if (err) {
      showNotification(err.message, 'warning')
      return
    }
    showNotification('Item atualizado com sucesso!')
    redirectTo(PARTNER_ROUTES.LIST)
  }

  const handleChange = field => e => {
    setPartner({
      ...partner,
      [field]: { ...partner[field], value: e.target.value, touched: true },
    })
  }

  const handleCep = async event => {
    const {
      target: { value },
    } = event
    setPartner({ ...partner, cep: { value, touched: true } })

    if (removeMask(value).length === 8) {
      const errorMessage = await fetchCep(value, partner, setPartner)
      errorMessage && showNotification(errorMessage, 'warning')
    }
  }

  const handleSubmit = async () => {
    if (checkEmptyFields(partner, setPartner)) {
      showNotification(
        'Este formulário não está válido. Certifique-se de corrigir os erros!',
        'warning',
      )
      return
    }
    const formData = parseFormData(partner)
    await submitForm(formData)
  }

  const handleDelete = async () => {
    const url = PARTNER_URL(match.params.id)

    try {
      await to(destroy(url))
      showNotification('Item removido com sucesso!')
      redirectTo(PARTNER_ROUTES.LIST)
    } catch ({ message }) {
      showNotification(message, 'warning')
    }
  }

  const checkCep = partner.cep.value.trim().length !== 10

  return (
    <Paper style={style.PaperStyle}>
      <Title title="Editar parceiro" />
      <FormInput
        label="Nome *"
        field={partner.name}
        onChange={handleChange('name')}
        isRequired
      />
      <FormInput
        label="CEP *"
        field={partner.cep}
        onChange={e => handleCep(e)}
        isRequired
        inputComponent={MaskCep}
      />
      <FormInput
        label="Rua *"
        field={partner.street}
        onChange={handleChange('street')}
        isRequired
        disabled={checkCep}
      />
      <FormInput
        label="Número *"
        field={partner.streetNumber}
        onChange={handleChange('streetNumber')}
        isRequired
      />
      <FormInput
        label="Bairro *"
        field={partner.neighbourhood}
        onChange={handleChange('neighbourhood')}
        isRequired
        disabled={checkCep}
      />
      <FormInput
        label="Cidade *"
        field={partner.city}
        onChange={handleChange('city')}
        isRequired
        disabled={checkCep}
      />
      <FormInput
        label="Estado *"
        field={partner.state}
        onChange={handleChange('state')}
        isRequired
        disabled={checkCep}
        inputProps={{ maxLength: 2 }}
        aditionalValidation={partner.state.value.length < 2}
      />
      <FormInput
        label="Telefone *"
        field={partner.phone}
        onChange={handleChange('phone')}
        isRequired
        inputComponent={MaskCellPhone}
      />
      <FormInput
        label="CNPJ *"
        field={partner.cnpj}
        onChange={handleChange('cnpj')}
        isRequired
        inputComponent={MaskCNPJ}
      />
      <FormInput
        label="Contato"
        field={partner.contact}
        onChange={handleChange('contact')}
      />
      <div style={style.ButtonsWrapper}>
        <Button variant="contained" color="secondary" onClick={handleSubmit}>
          <SaveIcon style={style.IconStyle} />
          Salvar
        </Button>
        <Button
          color="primary"
          onClick={handleDelete}
          style={style.DeleteIconStyle}
        >
          <DeleteIcon style={style.IconStyle} />
          Deletar
        </Button>
      </div>
    </Paper>
  )
}

export default connect(
  null,
  { showNotification },
)(EditPartner)
