import Bookmark from '@material-ui/icons/Bookmark'
import { PERMISSION_TYPE } from '@/constants'
import list from './reportLocalCoupons'
const { PARTNER_MANAGER } = PERMISSION_TYPE

export default {
  list,
  name: 'report-local-coupons',
  permission: [PARTNER_MANAGER],
  options: { label: 'Relatório de Cupons' },
  icon: Bookmark,
}