import { to, patch, makePayloadWithFile } from '@lib'
import {
  DEFAULT_API_ADMIN,
  MONEY_PRIZE_DESCRIPTION,
  MONEY_PRODUCTS,
} from '@/constants'
import { getChainId, clearMask } from '@lib'
import { pickBy } from 'lodash'

const handleMoneyProductsCreation = payload => {
  const _payload = {
    ...payload,
    isCash: true,
    description: MONEY_PRIZE_DESCRIPTION,
  }

  const body = new FormData()

  Object.keys(_payload).map(key => {
    const value = _payload[key]
    if (Array.isArray(value)) {
      body.append(key, JSON.stringify(value))
    } else {
      body.append(key, value)
    }
  })

  return body
}

export default async (resourceName, params) => {
  const filteredParams = pickBy(
    params.data,
    (obj, key) => obj !== params.previousData[key],
  )

  const mapPath = {
    _chains: 'chains',
    _employees: 'employees',
    'money-products': `chains/${getChainId()}/products`,
  }

  const path = mapPath[resourceName]
  const URL = `${DEFAULT_API_ADMIN}/${
    path ? path : (resourceName || '').replace('id', getChainId())
  }/${params.id}`

  const hasUploadFile = ['_chains', 'chains/id/products'].find(
    _resourceName => _resourceName === resourceName,
  )

  let payload =
    resourceName === '_chains' || 'chains/id/partners'
      ? clearMask(filteredParams, ['phone', 'cnpj'])
      : filteredParams

  if (payload.places) {
    payload.places = payload.places.map(({ placeId, price }) => ({
      placeId,
      price,
    }))
  }

  if (payload.productPlaces) {
    delete payload.productPlaces
  }

  if (resourceName === MONEY_PRODUCTS) {
    payload = handleMoneyProductsCreation(payload)
  }

  const [error, resource] = await to(
    patch(
      URL,
      hasUploadFile
        ? makePayloadWithFile(payload, ['logo', 'image', 'imagefile'])
        : payload,
    ),
  )
  if (error) {
    console.log(error)
  }

  return resource
}
