import React from 'react'
import { Auth, User } from './index'
import { JWT_TOKEN, DEFAULT_API_ADMIN, USER_KEY } from '@/constants'
import { post, to } from '@lib'

interface AuthActionsProps {
  setAuth: React.Dispatch<React.SetStateAction<Auth>>
}

export default ({ setAuth }: AuthActionsProps) => {
  const updateUser = (user: User) => {
    const { authorization } = user
    localStorage.setItem(JWT_TOKEN, authorization)
    setAuth((prevAuth: Auth) => {
      localStorage.setItem(USER_KEY, JSON.stringify(user))

      return { ...prevAuth, user }
    })

    return user
  }

  const doLogin = async (login, password) => {
    const URL_LOGIN = `${DEFAULT_API_ADMIN}/employees/login`
    const [err, user] = await to<User>(post(URL_LOGIN, { login, password }))

    if (err) {
      return err
    }

    return updateUser(user)
  }

  const doLogout = () => {
    localStorage.removeItem(JWT_TOKEN)

    setAuth(prevAuth => ({
      ...prevAuth,
      user: undefined,
    }))
  }

  return { doLogin, doLogout }
}
