import React from 'react'
import {
  List,
  Filter,
  Datagrid,
  TextInput,
  TextField,
  SelectInput,
  ReferenceInput,
  ReferenceField,
} from 'react-admin'

const PlacesUsersFilter = props => (
  <Filter {...props}>
    <TextInput label="Pesquisar" source="search" alwaysOn />
    <ReferenceInput
      label="Parceiro"
      source="placeId"
      reference="places"
      allowEmpty
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
)

export default props => (
  <List
    {...props}
    exporter={false}
    bulkActionButtons={false}
    filters={<PlacesUsersFilter />}
    title="Usuários de Parceiros"
  >
    <Datagrid rowClick="edit">
      <ReferenceField
        label="Parceiro"
        reference="places"
        source="placeId"
        linkType={false}
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" label="Nome" />
      <TextField source="login" label="Login" />
      <TextField source="role" label="Tipo de acesso" />
    </Datagrid>
  </List>
)
