import React, { useState, useEffect } from 'react'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'
import { PERMISSION_TYPE, DEFAULT_API_ADMIN } from '@/constants'
import hasPermission from '@lib/hasPermission'

import {
  Wrapper,
  RowFilter,
  Loader,
  Title,
  TableGeneric,
  DropDownPartners,
  InnerRow,
} from '@components'
import { DatePicker } from 'material-ui-pickers'

import moment from 'moment'
import { to, getChainId, get } from '@lib'

const { CHAIN_MANAGER, CHAIN_OPERATOR } = PERMISSION_TYPE

const Permission = ({ roles, ...props }) =>
  hasPermission(roles) ? <div {...props} /> : null

const CouponsReports = ({ data }) => (
  <>
    <TableGeneric
      headCells={['Total', 'Não resgatados', 'Resgatados']}
      arrayData={data.consolidatedData}
      title="Dados"
      style={{ width: '100%', margin: '1em 0' }}
    />
    <TableGeneric
      headCells={[
        'Cupom',
        'Produto',
        'Unidade de troca',
        'Cliente',
        'Resgatado',
      ]}
      arrayData={data.tableData}
      title="Relatório"
      style={{ width: '100%' }}
    />
  </>
)

export default () => {
  const [dateFrom, setDateFrom] = useState(moment().subtract(90, 'days'))
  const [dateTo, setDateTo] = useState(moment())
  const [places, setPlaces] = useState(null)
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [selectedPlace, setSelectedPlace] = useState(null)

  const getPlaceName = (placeId, _places) => {
    const { name } = _places.find(item => item.id === placeId)

    return name
  }

  const fetchPlaces = async () => {
    const URL = `${DEFAULT_API_ADMIN}/places`
    const [error, response] = await to(get(URL))

    if (error) {
      return
    }

    const formattedResponse = response.data.map(item => ({
      id: item.id,
      name: item.name,
    }))

    return formattedResponse
  }

  const fetchData = async () => {
    let filter = {
      from: moment(dateFrom).format('YYYY-MM-DD'),
      to: moment(dateTo).format('YYYY-MM-DD'),
      chainId: getChainId(),
    }

    if (selectedPlace) {
      filter = {
        ...filter,
        // @ts-ignore
        placeId: selectedPlace,
      }
    }

    const URL = `${DEFAULT_API_ADMIN}/coupons?filter=${JSON.stringify(filter)}`
    const [error, response] = await to(get(URL))

    if (error) {
      setLoading(false)
      return
    }

    return response
  }

  const doFetch = async () => {
    setLoading(true)
    const placesData = places ? places : await fetchPlaces()
    const couponsData = await fetchData()

    if (couponsData) {
      const { data: responseData, ...consolidation } = couponsData
      const formattedResponseData = responseData.map(item => ({
        id: item.id,
        productName: item.product.name,
        placeName: getPlaceName(item.placeId, placesData),
        clientName: item.transaction.customer.name,
        redeemed: item.redeemed ? 'Resgatado' : 'Não resgatado',
      }))

      const _consolidation = {
        total: consolidation.total,
        notRedeemed: consolidation.notRedeemed,
        redeemed: consolidation.redeemed,
      }

      const responseObject = {
        tableData: formattedResponseData,
        consolidatedData: [_consolidation],
      }

      // @ts-ignore
      setData(responseObject)
    }
    setLoading(false)
  }

  useEffect(() => {
    const onInit = async () => {
      const placesData = await fetchPlaces()
      setPlaces(placesData)
    }

    onInit()
  },        [])

  useEffect(() => {
    doFetch()
  },        [dateTo, dateFrom, selectedPlace])

  return (
    <Permission roles={[CHAIN_MANAGER, CHAIN_OPERATOR]}>
      <Wrapper>
        <RowFilter>
          <InnerRow>
            <DatePicker
              format={'DD/MM/YYYY'}
              label="De"
              value={dateFrom}
              onChange={date => setDateFrom(date)}
            />
            <DatePicker
              format={'DD/MM/YYYY'}
              label="Até"
              value={dateTo}
              onChange={date => setDateTo(date)}
            />
            <DropDownPartners
              style={{ width: '200px' }}
              label="Unidade de troca"
              value={selectedPlace}
              setValue={setSelectedPlace}
              arrayData={places && places}
            />
          </InnerRow>
          {selectedPlace && (
            <IconButton onClick={() => setSelectedPlace(null)}>
              <ClearIcon />
            </IconButton>
          )}
        </RowFilter>
        <Title text="Relatórios  de cupons" />
        {loading ? <Loader /> : data ? <CouponsReports data={data} /> : null}
      </Wrapper>
    </Permission>
  )
}
