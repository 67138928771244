import React from 'react'
import { withStyles } from '@material-ui/core'
import { Login } from 'react-admin'
import logo from '@assets/images/px-logo.png'

const LoginWithoutBgImage = props => <Login {...props} backgroundImage={null} />

export default withStyles({
  main: {
    backgroundColor: 'rgb(237, 238, 240)',
  },
  avatar: {
    background: `url(${logo})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPositionX: '50%',
    height: 60,
  },
  icon: { display: 'none' },
  card: {
    paddingBottom: '30px',
    '&:after': {
      content: `"#${process.env.REACT_APP_BUILD}"`,
      position: 'absolute',
      width: '50px',
      left: '50%',
      marginLeft: '-25px',
      marginTop: '5px',
      textAlign: 'center',
      fontSize: '10px',
      color: 'gray',
    }
  }
})(LoginWithoutBgImage)
