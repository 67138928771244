import React from 'react'
import { Route } from 'react-router-dom'
import {
  ReportAddedPoints,
  ReportPoints,
  ReportRedemption,
  ReportUnusedPoints,
  ReportCoupons,
} from './resources/reports'

import CreateStation from './resources/stations/CreateStation'
import EditStation from './resources/stations/EditStation'
import CreatePartner from './resources/partners/CreatePartner'
import EditPartner from './resources/partners/EditPartner'

export default [
  <Route exact path="/report-points" component={ReportPoints} />,
  <Route exact path="/report-added-points" component={ReportAddedPoints} />,
  <Route exact path="/report-redemption" component={ReportRedemption} />,
  <Route exact path="/report-unused-points" component={ReportUnusedPoints} />,
  <Route exact path="/report-coupons" component={ReportCoupons} />,
  <Route exact path="/stations-create" component={CreateStation} />,
  <Route exact path="/stations-edit/:id" component={EditStation} />,
  <Route exact path="/chains/id/partners-create" component={CreatePartner} />,
  <Route exact path="/chains/id/partners-edit/:id" component={EditPartner} />,
]
