import { Auth, User } from './index'
import { USER_KEY } from '@/constants'

export default ($auth: Auth) => {

  const getUser = (): User =>
    $auth.user ? $auth.user : JSON.parse(localStorage.getItem(USER_KEY) || '')

  return {
    getUser,
  }
}
