import { to, get } from '@lib'
import { DEFAULT_API_ADMIN } from '@/constants'
import { getChainId } from '@lib'

export default async (resourceName, params) => {
  const mapPath = {
    _chains: 'chains',
    _employees: 'employees',
    'money-products': `chains/${getChainId()}/products`,
  }
  const path = mapPath[resourceName]

  const URL = `${DEFAULT_API_ADMIN}/${path ? path : (resourceName || '').replace('id', getChainId())}/${params.id}`
  const [error, resource] = await to(get(URL))

  if (resource.data.productPlaces) {
    resource.data.places = resource.data.productPlaces
    delete resource.data.productPlaces
  }

  if (error) {
    console.log(error)
  }

  return resource
}
