import Redeem from '@material-ui/icons/Redeem'
import { PERMISSION_TYPE } from '@/constants'
import list from './MoneyProductsList'
import create from './MoneyProductsCreate'
import edit from './MoneyProductsEdit'

const { CHAIN_MANAGER, CHAIN_OPERATOR } = PERMISSION_TYPE

export default {
  list,
  create,
  edit,
  name: 'money-products',
  permission: [CHAIN_MANAGER, CHAIN_OPERATOR],
  options: { label: 'Produtos em dinheiro' },
  icon: Redeem,
}
