import React, { useState, useEffect } from 'react'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'

import { PERMISSION_TYPE, DEFAULT_API_ADMIN } from '@/constants'
import hasPermission from '@lib/hasPermission'

import {
  Wrapper,
  RowFilter,
  Loader,
  TableGeneric,
  DropDownPartners,
  InnerRow,
} from '@components'
import { DatePicker } from 'material-ui-pickers'

import moment from 'moment'
import { to, getChainId, get } from '@lib'
import useFetch from '@lib/useFetch'

const { CHAIN_MANAGER, CHAIN_OPERATOR } = PERMISSION_TYPE

const Permission = ({ roles, ...props }) =>
  hasPermission(roles) ? <div {...props} /> : null

const PointsReport = ({ data }) => (
  <div style={{ marginTop: '20px' }}>
    <TableGeneric
      headCells={['Total']}
      arrayData={[data]}
      title="Pontos não utilizados"
      style={{ width: '100%' }}
    />
  </div>
)

export default props => {
  const [points, setPoints] = useState(null)
  const [placeId, setPlaceId] = useState(null)
  const [dateFrom, setDateFrom] = useState(moment().subtract(90, 'days'))
  const [dateTo, setDateTo] = useState(moment())
  const [loading, setLoading] = useState(true)

  const [{ data: locals }] = useFetch({
    url: `${DEFAULT_API_ADMIN}/places`,
    initialState: { data: [] },
  })

  const getChoices = () =>
    locals
      .filter(item => item.chainId === getChainId())
      .map(({ id, name }) => ({ id, name }))

  const doFetch = async () => {
    setLoading(true)
    const filter = {
      placeId,
      from: moment(dateFrom).format('YYYY-MM-DD'),
      to: moment(dateTo).format('YYYY-MM-DD'),
    }

    const URL = `${DEFAULT_API_ADMIN}/chains/${getChainId()}/reports/unused-points?filter=${JSON.stringify(
      filter,
    )}`

    const [, response] = await to(get(URL))

    setPoints(response)
    setLoading(false)
  }

  useEffect(() => {
    doFetch()
  }, [dateFrom, dateTo, placeId])

  return (
    <Permission roles={[CHAIN_MANAGER, CHAIN_OPERATOR]}>
      <Wrapper>
        <RowFilter>
          <InnerRow>
            <DatePicker
              format={'DD/MM/YYYY'}
              label="De"
              value={dateFrom}
              onChange={date => setDateFrom(date)}
            />
            <DatePicker
              format={'DD/MM/YYYY'}
              label="Até"
              value={dateTo}
              onChange={date => setDateTo(date)}
            />
            <DropDownPartners
              style={{ width: '200px' }}
              label="Unidade de troca"
              value={placeId}
              setValue={setPlaceId}
              arrayData={getChoices()}
            />
          </InnerRow>

          {placeId && (
            <IconButton onClick={() => setPlaceId(null)}>
              <ClearIcon />
            </IconButton>
          )}
        </RowFilter>
        {loading ? <Loader /> : <PointsReport data={points} />}
      </Wrapper>
    </Permission>
  )
}
