import React from 'react';
import MaskedInput from 'react-text-mask';

export const MaskCellPhone = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[
        '(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-',
        /\d/, /\d/, /\d/, /\d/,
      ]}
      placeholderChar={'\u2000'}
    />
  );
};

export const MaskCep = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[
        /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/,
      ]}
      placeholderChar={'\u2000'}
    />
  );
};

export const MaskCNPJ = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[
        /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/,
        '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/,
      ]}
      placeholderChar={'\u2000'}
    />
  );
};
