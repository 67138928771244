import { getPlaceId } from '@lib'

export default (data, resourceName) => {
  const _data = data

  if (resourceName === 'employees') {
    _data.filter = { ..._data.filter, isPlace: false }
  }

  if (resourceName === '_employees') {
    _data.filter = { ..._data.filter, isPlace: true }
  }

  if (resourceName === 'money-products') {
    _data.filter = { ..._data.filter, isCash: true }
  }

  if (resourceName === 'chains/id/products') {
    _data.filter = { ..._data.filter, isCash: false }
  }

  if (resourceName === 'report-local-coupons') {
    _data.filter = { ..._data.filter, placeId: getPlaceId() }
  }

  return _data
}
