import React, { useState } from 'react'
import { stringify } from 'query-string'
import { connect } from 'react-redux'
import {
  Paper,
  Select,
  Button,
  MenuItem,
  InputLabel,
  FormControl,
} from '@material-ui/core'
import { showNotification } from 'react-admin'
import { Title } from 'react-admin'
import SaveIcon from '@material-ui/icons/Save'
import useFetch, { method } from '@lib/useFetch'
import { post } from '@lib/request'
import { to, redirectTo } from '@lib'
import { DEFAULT_API_ADMIN, STATION_ROUTES } from '@/constants'
import { MaskCellPhone, MaskCNPJ, MaskCep } from '../../components'
import { FormInput } from '../../components/PageComponents'
import {
  StationFormStyles as style,
  parseFormData,
  checkEmptyFields,
  fetchCep,
  removeMask,
} from '@/formLib'

const defaultState = { value: '', touched: false }

const CHAINS_FETCH_URL = `${DEFAULT_API_ADMIN}/chains?${stringify({
  order: JSON.stringify({
    id: 'DESC',
  }),
})}`

const SAVE_STATION_URL = `${DEFAULT_API_ADMIN}/stations`

const CreateStation = ({ showNotification }) => {
  const [station, setStation] = useState({
    chainId: defaultState,
    name: defaultState,
    city: defaultState,
    state: defaultState,
    cep: defaultState,
    neighbourhood: defaultState,
    street: defaultState,
    streetNumber: defaultState,
    phone: defaultState,
    cnpj: defaultState,
    latitude: defaultState,
    longitude: defaultState,
  })

  const [{ data: chains }] = useFetch({
    url: CHAINS_FETCH_URL,
    method: method.GET,
    initialState: { data: [] },
  })

  const submitForm = async value => {
    const url = SAVE_STATION_URL

    const [err, resource] = await to(post(url, value))

    if (err) {
      showNotification(err.message, 'warning')
      return
    }

    showNotification('Item criado com sucesso!')
    redirectTo(STATION_ROUTES.EDIT(resource.data.id))
  }

  const handleChange = field => e => {
    setStation({
      ...station,
      [field]: { value: e.target.value, touched: true },
    })
  }

  const handleCep = async event => {
    const {
      target: { value },
    } = event
    setStation({ ...station, cep: { value, touched: true } })

    if (removeMask(value).length === 8) {
      const errorMessage = await fetchCep(value, station, setStation)
      errorMessage && showNotification(errorMessage, 'warning')
    }
  }

  const handleSubmit = async () => {
    if (checkEmptyFields(station, setStation)) {
      showNotification(
        'Este formulário não está valido. Certifique-se de corrigir os erros!',
        'warning',
      )
      return
    }
    const formData = parseFormData(station)
    await submitForm(formData)
  }

  const checkCep = station.cep.value.trim().length !== 10

  return (
    <Paper style={style.PaperStyle}>
      <Title title="Cadastrar novo posto de combustível" />
      <FormControl style={style.FormControlStyle}>
        <InputLabel htmlFor="chain">Rede</InputLabel>
        <Select
          value={station.chainId.value}
          onChange={handleChange('chainId')}
        >
          <MenuItem value=""></MenuItem>
          {chains.map(chain => (
            <MenuItem value={chain.id} key={chain.id}>
              {chain.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormInput
        label="Nome *"
        field={station.name}
        onChange={handleChange('name')}
        isRequired
      />
      <FormInput
        label="CEP *"
        field={station.cep}
        onChange={e => handleCep(e)}
        isRequired
        inputComponent={MaskCep}
      />
      <FormInput
        label="Rua *"
        field={station.street}
        onChange={handleChange('street')}
        isRequired
        disabled={checkCep}
      />
      <FormInput
        label="Número *"
        field={station.streetNumber}
        onChange={handleChange('streetNumber')}
        isRequired
      />
      <FormInput
        label="Bairro *"
        field={station.neighbourhood}
        onChange={handleChange('neighbourhood')}
        isRequired
        disabled={checkCep}
      />
      <FormInput
        label="Cidade *"
        field={station.city}
        onChange={handleChange('city')}
        isRequired
        disabled={checkCep}
      />
      <FormInput
        label="Estado *"
        field={station.state}
        onChange={handleChange('state')}
        isRequired
        disabled={checkCep}
        inputProps={{ maxLength: 2 }}
        aditionalValidation={station.state.value.length < 2}
      />
      <FormInput
        label="Telefone *"
        field={station.phone}
        onChange={handleChange('phone')}
        isRequired
        inputComponent={MaskCellPhone}
      />
      <FormInput
        label="CNPJ *"
        field={station.cnpj}
        onChange={handleChange('cnpj')}
        isRequired
        inputComponent={MaskCNPJ}
      />
      <FormInput
        label="Latitude *"
        field={station.latitude}
        onChange={handleChange('latitude')}
        isRequired
      />
      <FormInput
        label="Longitude *"
        field={station.longitude}
        onChange={handleChange('longitude')}
        isRequired
      />
      <div style={style.ButtonWrapper}>
        <Button variant="contained" color="secondary" onClick={handleSubmit}>
          <SaveIcon style={style.IconStyle} />
          Salvar
        </Button>
      </div>
    </Paper>
  )
}

export default connect(
  null,
  { showNotification },
)(CreateStation)
