import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  RadioButtonGroupInput,
  SelectInput,
  required,
  minLength,
} from 'react-admin'

import { DEFAULT_API_ADMIN } from '@/constants'
import { getChainId } from '@lib'
import useFetch from '@lib/useFetch'

const validate = [required()]
const validatePassword = [required(), minLength(6)]

export default props => {
  const [{ data }] = useFetch({
    url: `${DEFAULT_API_ADMIN}/places`,
    initialState: { data: [] },
  })

  const getChoices = () =>
    data
      .filter(item => item.chainId === getChainId())
      .map(({ id, name }) => ({ id, name }))

  return (
    <Create {...props} title="Cadastrar novo usuário">
      <SimpleForm>
        <SelectInput
          label="Parceiro ou Posto"
          source="placeId"
          choices={data ? getChoices() : []}
        />
        <TextInput source="name" label="Nome" />
        <TextInput source="login" label="Login" validate={validate} />
        <TextInput
          source="password"
          label="Senha"
          validate={validatePassword}
        />
        <RadioButtonGroupInput
          label="Tipo de usuário"
          source="role"
          choices={[
            { id: 'manager', name: 'Parceiro gerencial' },
            { id: 'cashier', name: 'Parceiro operador' },
          ]}
          validate={validate}
        />
      </SimpleForm>
    </Create>
  )
}
