import React from 'react'
import { Edit, SimpleForm, TextInput, required, minLength } from 'react-admin'
import { MaskCellPhone } from '../../components'
import { BalanceButton } from '@components'

const validatePassword = [required(), minLength(6)]

export default props => {
  console.log('props', props)

  return (
    <Edit {...props} title="Editar cliente">
      <SimpleForm>
        <TextInput source="name" label="Nome" />
        <TextInput source="email" label="Email" />
        <TextInput
          source="cellphone"
          label="Telefone"
          InputProps={{
            inputComponent: MaskCellPhone,
          }}
        />
        <TextInput
          source="password"
          label="Alterar Senha"
          validate={validatePassword}
        />
        <BalanceButton clientId={props.id} />
      </SimpleForm>
    </Edit>
  )
}
