import ConfirmationIcon from '@material-ui/icons/ConfirmationNumber'
import { PERMISSION_TYPE } from '@/constants'
import list from './CouponSearch'

const { PARTNER_OPERATOR } = PERMISSION_TYPE

export default {
  list,
  name: 'coupons',
  permission: [PARTNER_OPERATOR],
  options: { label: 'Validar Cupom' },
  icon: ConfirmationIcon,
}
