import React from 'react'
import {
	Edit,
	SimpleForm,
	TextInput,
	NumberInput,
	RadioButtonGroupInput,
	minLength,
	ReferenceInput,
	SelectInput
} from 'react-admin'

import hasPermission from '@lib/hasPermission';
import { PERMISSION_TYPE } from '@/constants';

const { ADMIN } = PERMISSION_TYPE

const Permission = ({ roles, children, ...props }) => hasPermission(roles) ? (children(props)) : null

const validatePassword = [minLength(6)];

export default props => (
	<Edit {...props} title="Editar usuário">
		<SimpleForm>
			<Permission roles={[ADMIN]} >
				{props => (
					<>
						<ReferenceInput {...props} label="Rede" source="chainId" reference="_chains" allowEmpty >
						<SelectInput optionText="name" />
						</ReferenceInput>
					</>
				)}
			</Permission>
			<TextInput source="name" label="Nome" />
			<TextInput source="login" label="Login" />
			<TextInput source="password" label="Senha" validate={validatePassword} />
			<RadioButtonGroupInput label="Tipo de usuário" source="role" choices={[
				{ id: 'manager', name: 'Rede administrador geral' },
				{ id: 'operator', name: 'Rede operador' },
			]} />
			<NumberInput source="maxPoints" label="Pontos por CPF" />

		</SimpleForm>
	</Edit>
)
