import React, { Fragment } from 'react'
import {
  List,
  Filter,
  Datagrid,
  TextField,
  ImageField,
  TextInput,
  BulkDeleteButton,
  ReferenceField,
} from 'react-admin'
import { withStyles } from '@material-ui/core/styles'

const Filters = props => (
  <Filter {...props}>
    <TextInput label="Nome" source="search" />
  </Filter>
)

const listStyles = {
  image: {
    '& img': {
      maxWidth: '10rem',
    },
  },
}

const BulkActionButtons = props => (
  <BulkDeleteButton {...props} label="Inativar" />
)

const list = withStyles(listStyles)(({ classes, ...props }) => (
  <List
    {...props}
    exporter={false}
    title="Produtos"
    filters={<Filters />}
    bulkActionButtons={<BulkActionButtons />}
  >
    <Datagrid rowClick="edit">
      <ImageField className={classes.image} source="imagePath" label="Imagem" />
      <TextField source="name" label="Nome" />
      <TextField source="description" label="Descrição" />
    </Datagrid>
  </List>
))

export default list
