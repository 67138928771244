import UserIcon from '@material-ui/icons/People'
import { PERMISSION_TYPE } from '@/constants'
import list from './UserList'
import create from './UserCreate'
import edit from './UserEdit'

const { CHAIN_MANAGER, ADMIN } = PERMISSION_TYPE

export default {
  edit,
  list,
  create,
  name: 'employees',
  permission: [CHAIN_MANAGER, ADMIN],
  options: { label: 'Usuários da rede' },
  icon: UserIcon,
}
