import { to, destroy } from '@lib'
import { DEFAULT_API_ADMIN } from '@/constants'
import { getChainId } from '@lib'

export default async (resourceName, params) => {
  const mapPath = {
    '_chains': 'chains',
    'money-products': 'products',
  }
  const path = mapPath[resourceName]

  let URL

  if (resourceName === 'money-products') {
    URL = `${DEFAULT_API_ADMIN}/chains/${getChainId()}/products/${params.id}`
  } else {
    URL = `${DEFAULT_API_ADMIN}/${path ? path : (resourceName || '').replace('id', getChainId())}/${params.id}`
  }
  const [error, resource] = await to(destroy(URL))

  if (error) {
    console.log(error)
  }

  return resource
}
