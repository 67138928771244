import { ComponentType } from 'react'
import Chains from './chains'
import Users from './users'
import Clients from './clients'
import Products from './products'
import Partners from './partners'
import Stations from './stations'
import PlacesUSers from '././placesUsers'
import ValidateCoupon from './validateCoupon'
import Places from './places'
import ReportLocalCoupons from './reportLocalCoupons'
import MoneyProducts from './moneyProducts'
import { PermissionType } from '@/constants'

export interface Resource {
  name: string
  permission: PermissionType
  list?: ComponentType
  create?: ComponentType
  edit?: ComponentType
  show?: ComponentType
  icon?: ComponentType
  options?: { label: string }
}

export default [
  Chains,
  Users,
  PlacesUSers,
  Clients,
  Partners,
  Products,
  MoneyProducts,
  Stations,
  ValidateCoupon,
  Places,
  ReportLocalCoupons,
] as Resource[]
