import LocalGasStation from '@material-ui/icons/LocalGasStation'
import { PERMISSION_TYPE } from '@/constants'
import list from './StationsList'
import create from './CreateStation'
import edit from './EditStation'
const { ADMIN } = PERMISSION_TYPE

export default {
  list,
  edit,
  create,
  name: 'stations',
  permission: [ADMIN],
  options: { label: 'Postos de combustível' },
  icon: LocalGasStation,
}
