import { getChainId } from '@lib'

export const JWT_TOKEN = 'jwt-token'
export const DEFAULT_API_ADMIN =
  process.env.REACT_APP_API_ADMIN || 'https://api-dev.pontuax.com.br'

export const EXPIRED = 'timeout'
export const USER_KEY = 'user'
export const GUID_REGEX = /(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}/

export type PermissionType = (
  | 'admin'
  | 'partner-manager'
  | 'chain-manager'
  | 'cashier'
  | 'chain-operator')[]

export const PERMISSION_TYPE = {
  ADMIN: 'admin',
  PARTNER_MANAGER: 'partner-manager',
  PARTNER_OPERATOR: 'cashier',
  CHAIN_MANAGER: 'chain-manager',
  CHAIN_OPERATOR: 'chain-operator',
}

export const MONEY_PRIZE_DESCRIPTION = 'Pr�mio em dinheiro'
export const MONEY_PRODUCTS = 'money-products'
export const MANAGER = 'manager'
export const LOCAL_USERS = '_employees'
export const PRODUCT = 'chains/id/products'

export const chainIdDelete = [
  '_chains',
  'money-products',
  'chains/id/products',
  'chains/id/partners',
]
export const routesWithUpload = ['_chains', 'chains/id/products']
export const clearMaskRoutes = ['_chains', 'chains/id/partners', 'stations']

export const mapPath = {
  _chains: 'chains',
  _employees: 'employees',
  'money-products': `chains/${getChainId()}/products`,
  'report-local-coupons': 'coupons',
}

export const STATION_ROUTES = {
  LIST: '/#/stations',
  CREATE: '/#/stations-create',
  EDIT: (id: string): string => `/#/stations-edit/${id}`,
}

export const PARTNER_ROUTES = {
  LIST: '/#/chains/id/partners',
  CREATE: '/#/chains/id/partners-create',
  EDIT: (id: string): string => `/#/chains/id/partners-edit/${id}`,
}

export const CEP_TYPE_ERROR = 'Failed to fetch'
export const CEP_ERROR_MESSAGE = 'Erro de conexão com o serviço de CEP!'
export const CEP_NOT_FOUND_MESSAGE = 'CEP inválido ou não encontrado!'
